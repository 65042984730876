import React from "react";
import { Box, Stack } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { TennisLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";
import ElapsedTime from "../ElapsedTime";


type CompactTennisDesignProps = {
    gameLiveData: TennisLiveDataTypeFragment;
    elapsedTime: string;
    isFuture: boolean;
};


function CompactTennisDesign({ gameLiveData, elapsedTime, isFuture }: CompactTennisDesignProps) {
    const {
        player1GamesBySet = [],
        player2GamesBySet = [],
        isPlayer1Serving = false,
        player1Points = 0,
        player2Points = 0,
    } = gameLiveData;


    const currentSetIndex = Math.max((player1GamesBySet ?? []).length - 1, 0);
    const player1Score = player1Points?.toString();
    const player2Score = player2Points?.toString();

    return (
        <>
            <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                <SensorsIcon color="error" sx={{
                    height: "16px",
                }} />
            </Box>
            <Box>
                <Stack direction="row" gap={2} mt={0.5} pr={1}>
                    <Stack direction="column" spacing={1.5} alignItems="end" sx={{ flexGrow: 1 }}>
                        <ScoreIndicatorLiveData score={player1Score} isServing={isPlayer1Serving} design={LiveDataDesign.COMPACT} />
                        <ScoreIndicatorLiveData score={player2Score} isServing={!isPlayer1Serving} design={LiveDataDesign.COMPACT} />
                    </Stack>

                    <Stack direction="column" alignItems="center">
                        <StyledTypography color="text.primary" variant="caption">
                            {(player1GamesBySet ?? [])[currentSetIndex] ?? 0}
                        </StyledTypography>
                        <StyledTypography variant="supScript" color="text.secondary">
                            S{currentSetIndex + 1}
                        </StyledTypography>
                        <StyledTypography variant="caption">{(player2GamesBySet ?? [])[currentSetIndex] ?? 0}</StyledTypography>
                    </Stack>
                </Stack>

                <Stack sx={{ alignItems: "center" }}>
                    <ElapsedTime elapsedTime={elapsedTime} isFuture={isFuture} isgameLive />
                </Stack>
            </Box>
        </>
    );
};

export default CompactTennisDesign;
