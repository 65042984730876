import React from "react";
import { Box, Stack, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { TennisLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import { LiveDataDesign } from "../DesignEnum"
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";

type DefaultTennisDesignProps = {
    gameLiveData: TennisLiveDataTypeFragment;
    showAdditionalData?: boolean;
};


function DefaultTennisDesign({ gameLiveData, showAdditionalData }: DefaultTennisDesignProps) {
    const theme = useTheme();
    const {
        player1GamesBySet = [],
        player2GamesBySet = [],
        isPlayer1Serving = false,
        player1Points = 0,
        player2Points = 0,
        additionalData,
    } = gameLiveData;

    const player1Score = player1Points?.toString();
    const player2Score = player2Points?.toString();

    return (
        <Box>
            <Stack direction="row" spacing={1} alignItems="start">
                <Stack direction="row" spacing={1} alignItems="center" sx={{ width: "fit-content", minWidth: "110px" }}>
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            borderRight: `0.5px solid ${theme.palette.border.primary}`,
                            pr: 1,
                            flexShrink: 0,
                        }}
                    >
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                            {player1GamesBySet?.map((games, setIndex) => (
                                <StyledTypography color="text.primary" variant="body2" key={`player1-set-${setIndex}`}>
                                    {games}
                                </StyledTypography>
                            ))}
                            <StyledTypography variant="body2" sx={{ ml: 1 }}>
                                -
                            </StyledTypography>
                        </Stack>

                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                            {player2GamesBySet?.map((games, setIndex) => (
                                <StyledTypography variant="body2" key={`player2-set-${setIndex}`}>
                                    {games}
                                </StyledTypography>
                            ))}
                            <StyledTypography variant="body2" sx={{ ml: 1 }}>
                                -
                            </StyledTypography>
                        </Stack>
                    </Stack>

                    <Stack direction="column" spacing={0} alignItems="start" sx={{ flexGrow: 1 }}>
                        <ScoreIndicatorLiveData score={player2Score} isServing={!isPlayer1Serving} design={LiveDataDesign.DEFAULT} />
                        <ScoreIndicatorLiveData score={player1Score} isServing={isPlayer1Serving} design={LiveDataDesign.DEFAULT} />
                    </Stack>
                </Stack>
                {
                    showAdditionalData && (
                        <Box>
                            <StyledTypography
                                lineClamp={3}
                                color="text.secondary"
                                variant="caption"
                                sx={{
                                    lineHeight: "1em",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    whiteSpace: "normal",
                                }}
                            >
                                {additionalData}
                            </StyledTypography>
                        </Box>
                    )}
            </Stack>
        </Box>
    );
};

export default DefaultTennisDesign;
