import { BET_MARKET_TYPE_GROUP_ENUM } from "../Common/Enums/BetMarketTypeGroupEnum";
import { betMarketTypeMenuLabels, BetMarketTypeTimeEnum } from "../Common/Enums/BetMarketTypeTimeEnum";
import { League } from "../Common/Enums/LeagueEnum";
import { TeamType } from "../Common/Fragments/ToolsSubscriptionFragment";
import { TABS_NAME } from "./tabs";

const getTimeFromEnum = (val: string) =>
  Object.values(BetMarketTypeTimeEnum)
    .slice(1)
    .find((time) => val?.toUpperCase().includes(time));

function replaceSubstringWithAnother(str: string, target: string, newString: string) {
  // Create a regular expression to match the target string with optional underscores around it
  const regex = new RegExp(`_?${target}_?`, "g");
  return str.replace(regex, newString);
}

export function getTabPanelFromBetGroupName(groupValue: BET_MARKET_TYPE_GROUP_ENUM) {
  return TABS_NAME.findIndex((tab) => tab.enum === groupValue);
}

// The First Parameter of return is Group, The second is Time. If the Time contains NoOt we send a thrid paramter as which NoOt tme is there
export function separateSecondAndTimeParam(
  value: string,
  groupValue: BET_MARKET_TYPE_GROUP_ENUM,
): [string, BetMarketTypeTimeEnum, BetMarketTypeTimeEnum?] {
  const getTime = getTimeFromEnum(value);
  const getRemanFromEnum = getTime
    ? replaceSubstringWithAnother(value?.toLowerCase(), getTime?.toLowerCase(), "_")
    : value?.toLowerCase();
  const trimUnderscore = getRemanFromEnum?.replace(/^_+|_+$/g, "");
  if (getTime === BetMarketTypeTimeEnum.NO_OT) {
    const [groupVal, getTimeNoot] = separateSecondAndTimeParam(trimUnderscore, groupValue);
    return [groupVal, getTime, getTimeNoot || BetMarketTypeTimeEnum.GAME];
  }

  if (trimUnderscore?.trim() === "") return [groupValue, getTime || BetMarketTypeTimeEnum?.GAME];
  if (!getTime) return [trimUnderscore, BetMarketTypeTimeEnum?.GAME];
  return [trimUnderscore, getTime];
}

export function getBetMarketTimeLabel(time: BetMarketTypeTimeEnum, noOtTime?: BetMarketTypeTimeEnum) {
  if (!noOtTime) return betMarketTypeMenuLabels[time]?.label;
  return betMarketTypeMenuLabels[noOtTime]?.label.concat(" (", betMarketTypeMenuLabels[time]?.label, ")");
}



export function getTeamName(team: TeamType, league: League, isMobile: boolean): string | undefined {
  if (league === League.NCAAF) {
    return team?.city;
  }
  if (isMobile) {
    if (league !== League.TENNIS) {
      return team?.abbreviations[0]; // Use only the first abbreviation
    }
    return team?.name;
  }
  return team?.name;
}