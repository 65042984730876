export enum BetMarketTypeEnum {
  PLAYER_PROP_POINTS = "PLAYER_PROP_POINTS",
  PLAYER_PROP_POINTS_FIRST_QUARTER = "PLAYER_PROP_POINTS_FIRST_QUARTER",
  PLAYER_PROP_POINTS_SECOND_QUARTER = "PLAYER_PROP_POINTS_SECOND_QUARTER",
  PLAYER_PROP_POINTS_THIRD_QUARTER = "PLAYER_PROP_POINTS_THIRD_QUARTER",
  PLAYER_PROP_POINTS_FOURTH_QUARTER = "PLAYER_PROP_POINTS_FOURTH_QUARTER",
  PLAYER_PROP_POINTS_ASSISTS = "PLAYER_PROP_POINTS_ASSISTS",
  PLAYER_PROP_POINTS_ASSISTS_REBOUNDS = "PLAYER_PROP_POINTS_ASSISTS_REBOUNDS",
  PLAYER_PROP_POINTS_REBOUNDS = "PLAYER_PROP_POINTS_REBOUNDS",
  PLAYER_PROP_POINTS_BLOCKS = "PLAYER_PROP_POINTS_BLOCKS",
  PLAYER_PROP_POINTS_REBOUNDS_BLOCKS = "PLAYER_PROP_POINTS_REBOUNDS_BLOCKS",
  PLAYER_PROP_ASSISTS = "PLAYER_PROP_ASSISTS",
  PLAYER_PROP_ASSISTS_FIRST_QUARTER = "PLAYER_PROP_ASSISTS_FIRST_QUARTER",
  PLAYER_PROP_ASSISTS_SECOND_QUARTER = "PLAYER_PROP_ASSISTS_SECOND_QUARTER",
  PLAYER_PROP_ASSISTS_THIRD_QUARTER = "PLAYER_PROP_ASSISTS_THIRD_QUARTER",
  PLAYER_PROP_ASSISTS_FOURTH_QUARTER = "PLAYER_PROP_ASSISTS_FOURTH_QUARTER",
  PLAYER_PROP_REBOUNDS = "PLAYER_PROP_REBOUNDS",
  PLAYER_PROP_REBOUNDS_FIRST_QUARTER = "PLAYER_PROP_REBOUNDS_FIRST_QUARTER",
  PLAYER_PROP_REBOUNDS_SECOND_QUARTER = "PLAYER_PROP_REBOUNDS_SECOND_QUARTER",
  PLAYER_PROP_REBOUNDS_THIRD_QUARTER = "PLAYER_PROP_REBOUNDS_THIRD_QUARTER",
  PLAYER_PROP_REBOUNDS_FOURTH_QUARTER = "PLAYER_PROP_REBOUNDS_FOURTH_QUARTER",
  PLAYER_PROP_REBOUNDS_ASSISTS = "PLAYER_PROP_REBOUNDS_ASSISTS",
  PLAYER_PROP_TWO_POINTER_MADE = "PLAYER_PROP_TWO_POINTER_MADE",
  PLAYER_PROP_TWO_POINTER_ATTEMPTED = "PLAYER_PROP_TWO_POINTER_ATTEMPTED",
  PLAYER_PROP_THREE_POINTER = "PLAYER_PROP_THREE_POINTER",
  PLAYER_PROP_THREE_POINTER_ATTEMPTED = "PLAYER_PROP_THREE_POINTER_ATTEMPTED",
  PLAYER_PROP_FREE_THROW_MADE = "PLAYER_PROP_FREE_THROW_MADE",
  PLAYER_PROP_FREE_THROW_ATTEMPTED = "PLAYER_PROP_FREE_THROW_ATTEMPTED",
  PLAYER_PROP_FIELD_GOAL_MADE = "PLAYER_PROP_FIELD_GOAL_MADE",
  PLAYER_PROP_FIELD_GOAL_ATTEMPTED = "PLAYER_PROP_FIELD_GOAL_ATTEMPTED",
  PLAYER_PROP_BLOCKS = "PLAYER_PROP_BLOCKS",
  PLAYER_PROP_STEALS = "PLAYER_PROP_STEALS",
  PLAYER_PROP_STEALS_BLOCKS = "PLAYER_PROP_STEALS_BLOCKS",
  PLAYER_PROP_TURNOVERS = "PLAYER_PROP_TURNOVERS",
  PLAYER_PROP_TURNOVERS_STEALS = "PLAYER_PROP_TURNOVERS_STEALS",
  PLAYER_PROP_TURNOVERS_STEALS_BLOCKS = "PLAYER_PROP_TURNOVERS_STEALS_BLOCKS",
  PLAYER_PROP_DOUBLE_DOUBLE = "PLAYER_PROP_DOUBLE_DOUBLE",
  PLAYER_PROP_TRIPLE_DOUBLE = "PLAYER_PROP_TRIPLE_DOUBLE",
  PLAYER_PROP_RUSHING_YARDS = "PLAYER_PROP_RUSHING_YARDS",
  PLAYER_PROP_PASSING_YARDS = "PLAYER_PROP_PASSING_YARDS",
  PLAYER_PROP_RECEIVING_YARDS = "PLAYER_PROP_RECEIVING_YARDS",
  PLAYER_PROP_PASSING_TOUCHDOWNS = "PLAYER_PROP_PASSING_TOUCHDOWNS",
  PLAYER_PROP_TOTAL_RECEPTIONS = "PLAYER_PROP_TOTAL_RECEPTIONS",
  PLAYER_PROP_TOTAL_SCRIMMAGE_YARDS = "PLAYER_PROP_TOTAL_SCRIMMAGE_YARDS",
  PLAYER_PROP_ANY_TIME_TOUCHDOWN = "PLAYER_PROP_ANY_TIME_TOUCHDOWN",
  PLAYER_PROP_ANY_TIME_TOUCHDOWN_FIRST_HALF = "PLAYER_PROP_ANY_TIME_TOUCHDOWN_FIRST_HALF",
  PLAYER_PROP_ANY_TIME_TOUCHDOWN_SECOND_HALF = "PLAYER_PROP_ANY_TIME_TOUCHDOWN_SECOND_HALF",
  PLAYER_PROP_ANY_TIME_TOUCHDOWN_FIRST_QUARTER = "PLAYER_PROP_ANY_TIME_TOUCHDOWN_FIRST_QUARTER",
  PLAYER_PROP_ANY_TIME_TOUCHDOWN_SECOND_QUARTER = "PLAYER_PROP_ANY_TIME_TOUCHDOWN_SECOND_QUARTER",
  PLAYER_PROP_ANY_TIME_TOUCHDOWN_THIRD_QUARTER = "PLAYER_PROP_ANY_TIME_TOUCHDOWN_THIRD_QUARTER",
  PLAYER_PROP_ANY_TIME_TOUCHDOWN_FOURTH_QUARTER = "PLAYER_PROP_ANY_TIME_TOUCHDOWN_FOURTH_QUARTER",
  PLAYER_PROP_ANY_TIME_RECEIVING_TOUCHDOWN = "PLAYER_PROP_ANY_TIME_RECEIVING_TOUCHDOWN",
  PLAYER_PROP_ANY_TIME_RUSHING_TOUCHDOWN = "PLAYER_PROP_ANY_TIME_RUSHING_TOUCHDOWN",
  PLAYER_PROP_THROWN_INTERCEPTIONS = "PLAYER_PROP_THROWN_INTERCEPTIONS",
  PLAYER_PROP_CAUGHT_INTERCEPTIONS = "PLAYER_PROP_CAUGHT_INTERCEPTIONS",
  PLAYER_PROP_PASSING_RUSHING_YARDS = "PLAYER_PROP_PASSING_RUSHING_YARDS",
  PLAYER_PROP_PASSING_COMPLETIONS = "PLAYER_PROP_PASSING_COMPLETIONS",
  PLAYER_PROP_PASSING_ATTEMPTS = "PLAYER_PROP_PASSING_ATTEMPTS",
  PLAYER_PROP_TACKLES_ASSISTS = "PLAYER_PROP_TACKLES_ASSISTS",
  PLAYER_PROP_SACKS = "PLAYER_PROP_SACKS",
  PLAYER_PROP_SOLO_TACKLES = "PLAYER_PROP_SOLO_TACKLES",
  PLAYER_PROP_LONGEST_RUSH = "PLAYER_PROP_LONGEST_RUSH",
  PLAYER_PROP_LONGEST_RECEPTION = "PLAYER_PROP_LONGEST_RECEPTION",
  PLAYER_PROP_RUSHING_ATTEMPTS = "PLAYER_PROP_RUSHING_ATTEMPTS",
  PLAYER_PROP_LONGEST_COMPLETION = "PLAYER_PROP_LONGEST_COMPLETION",
  PLAYER_PROP_SHOTS = "PLAYER_PROP_SHOTS",
  PLAYER_PROP_SHOTS_FIRST_HALF = "PLAYER_PROP_SHOTS_FIRST_HALF",
  PLAYER_PROP_SHOTS_SECOND_HALF = "PLAYER_PROP_SHOTS_SECOND_HALF",
  PLAYER_PROP_SHOTS_EACH_HALF = "PLAYER_PROP_SHOTS_EACH_HALF",
  PLAYER_PROP_SHOTS_ON_GOAL = "PLAYER_PROP_SHOTS_ON_GOAL",
  PLAYER_PROP_SHOTS_ON_GOAL_NO_OT = "PLAYER_PROP_SHOTS_ON_GOAL_NO_OT",
  PLAYER_PROP_SHOTS_ON_GOAL_FIRST_QUARTER = "PLAYER_PROP_SHOTS_ON_GOAL_FIRST_QUARTER",
  PLAYER_PROP_SHOTS_ON_GOAL_SECOND_QUARTER = "PLAYER_PROP_SHOTS_ON_GOAL_SECOND_QUARTER",
  PLAYER_PROP_SHOTS_ON_GOAL_THIRD_QUARTER = "PLAYER_PROP_SHOTS_ON_GOAL_THIRD_QUARTER",
  PLAYER_PROP_SHOTS_ON_GOAL_FOURTH_QUARTER = "PLAYER_PROP_SHOTS_ON_GOAL_FOURTH_QUARTER",
  PLAYER_PROP_SHOTS_ON_GOAL_EACH_HALF = "PLAYER_PROP_SHOTS_ON_GOAL_EACH_HALF",
  PLAYER_PROP_SHOTS_ON_GOAL_FIRST_HALF = "PLAYER_PROP_SHOTS_ON_GOAL_FIRST_HALF",
  PLAYER_PROP_SHOTS_ON_GOAL_SECOND_HALF = "PLAYER_PROP_SHOTS_ON_GOAL_SECOND_HALF",
  PLAYER_PROP_GOALS_SCORED = "PLAYER_PROP_GOALS_SCORED",
  PLAYER_PROP_GOALS_SCORED_FIRST_QUARTER = "PLAYER_PROP_GOALS_SCORED_FIRST_QUARTER",
  PLAYER_PROP_GOALS_SCORED_SECOND_QUARTER = "PLAYER_PROP_GOALS_SCORED_SECOND_QUARTER",
  PLAYER_PROP_GOALS_SCORED_THIRD_QUARTER = "PLAYER_PROP_GOALS_SCORED_THIRD_QUARTER",
  PLAYER_PROP_POWER_PLAY_POINTS = "PLAYER_PROP_POWER_PLAY_POINTS",
  PLAYER_PROP_BLOCKED_SHOTS = "PLAYER_PROP_BLOCKED_SHOTS",
  PLAYER_PROP_SHOTS_SAVED = "PLAYER_PROP_SHOTS_SAVED",
  PLAYER_PROP_SHOTS_SAVED_FIRST_QUARTER = "PLAYER_PROP_SHOTS_SAVED_FIRST_QUARTER",
  PLAYER_PROP_SHOTS_SAVED_SECOND_QUARTER = "PLAYER_PROP_SHOTS_SAVED_SECOND_QUARTER",
  PLAYER_PROP_SHOTS_SAVED_THIRD_QUARTER = "PLAYER_PROP_SHOTS_SAVED_THIRD_QUARTER",
  PLAYER_PROP_SHOTS_SAVED_NO_OT = "PLAYER_PROP_SHOTS_SAVED_NO_OT",
  PLAYER_PROP_GOALS_AGAINST = "PLAYER_PROP_GOALS_AGAINST",
  PLAYER_PROP_SHUTOUT = "PLAYER_PROP_SHUTOUT",
  PLAYER_PROP_TACKLE_SOLO_AND_ASSIST = "PLAYER_PROP_TACKLE_SOLO_AND_ASSIST",
  PLAYER_PROP_FIELD_GOALS_MADE = "PLAYER_PROP_FIELD_GOALS_MADE",
  PLAYER_PROP_KICKING_POINTS = "PLAYER_PROP_KICKING_POINTS",
  PLAYER_PROP_EXTRA_POINT_MADE = "PLAYER_PROP_EXTRA_POINT_MADE",
  PLAYER_PROP_FIRST_TOUCHDOWN = "PLAYER_PROP_FIRST_TOUCHDOWN",
  PLAYER_PROP_FIRST_TOUCHDOWN_SECOND_HALF = "PLAYER_PROP_FIRST_TOUCHDOWN_SECOND_HALF",
  PLAYER_PROP_FIRST_TOUCHDOWN_TEAM = "PLAYER_PROP_FIRST_TOUCHDOWN_TEAM",
  PLAYER_PROP_LAST_TOUCHDOWN = "PLAYER_PROP_LAST_TOUCHDOWN",
  PLAYER_PROP_CARD = "PLAYER_PROP_CARD",
  PLAYER_PROP_YELLOW_CARD = "PLAYER_PROP_YELLOW_CARD",
  PLAYER_PROP_RED_CARD = "PLAYER_PROP_RED_CARD",
  PLAYER_PROP_SCORE_OR_ASSIST = "PLAYER_PROP_SCORE_OR_ASSIST",
  PLAYER_PROP_SCORE_AND_ASSIST = "PLAYER_PROP_SCORE_AND_ASSIST",
  PLAYER_PROP_PASSES = "PLAYER_PROP_PASSES",
  PLAYER_PROP_RBI = "PLAYER_PROP_RBI",
  PLAYER_PROP_HIT = "PLAYER_PROP_HIT",
  PLAYER_PROP_SINGLE = "PLAYER_PROP_SINGLE",
  PLAYER_PROP_DOUBLE = "PLAYER_PROP_DOUBLE",
  PLAYER_PROP_TRIPLE = "PLAYER_PROP_TRIPLE",
  PLAYER_PROP_BASES = "PLAYER_PROP_BASES",
  PLAYER_PROP_THROWN_STRIKEOUTS = "PLAYER_PROP_THROWN_STRIKEOUTS",
  PLAYER_PROP_BATTED_STRIKEOUTS = "PLAYER_PROP_BATTED_STRIKEOUTS",
  PLAYER_PROP_GRAND_SLAM = "PLAYER_PROP_GRAND_SLAM",
  PLAYER_PROP_STOLEN_BASE = "PLAYER_PROP_STOLEN_BASE",
  PLAYER_PROP_HOME_RUN = "PLAYER_PROP_HOME_RUN",
  PLAYER_PROP_RUN = "PLAYER_PROP_RUN",
  PLAYER_PROP_BATTED_WALK = "PLAYER_PROP_BATTED_WALK",
  PLAYER_PROP_RUN_HIT_RBI = "PLAYER_PROP_RUN_HIT_RBI",
  PLAYER_PROP_RUN_RBI = "PLAYER_PROP_RUN_RBI",
  PLAYER_PROP_THROWN_WALK = "PLAYER_PROP_THROWN_WALK",
  PLAYER_PROP_THROWN_EARNED_RUN = "PLAYER_PROP_THROWN_EARNED_RUN",
  PLAYER_PROP_THROWN_HITS = "PLAYER_PROP_THROWN_HITS",
  PLAYER_PROP_THROWN_OUTS = "PLAYER_PROP_THROWN_OUTS",
  PLAYER_PROP_RECORDED_WIN = "PLAYER_PROP_RECORDED_WIN",
  PLAYER_PROP_RUN_HIT = "PLAYER_PROP_RUN_HIT",
  PLAYER_PROP_OFFSIDES = "PLAYER_PROP_OFFSIDES",
  PLAYER_PROP_SHOT_OUTSIDE_BOX = "PLAYER_PROP_SHOT_OUTSIDE_BOX",
  PLAYER_PROP_FIRST_GOAL_SCORED = "PLAYER_PROP_FIRST_GOAL_SCORED",
  PLAYER_PROP_FIRST_GOAL_SCORED_FIRST_QUARTER = "PLAYER_PROP_FIRST_GOAL_SCORED_FIRST_QUARTER",
  PLAYER_PROP_FIRST_GOAL_SCORED_SECOND_QUARTER = "PLAYER_PROP_FIRST_GOAL_SCORED_SECOND_QUARTER",
  PLAYER_PROP_FIRST_GOAL_SCORED_THIRD_QUARTER = "PLAYER_PROP_FIRST_GOAL_SCORED_THIRD_QUARTER",
  PLAYER_PROP_FIRST_GOAL_SCORED_FOURTH_QUARTER = "PLAYER_PROP_FIRST_GOAL_SCORED_FOURTH_QUARTER",
  PLAYER_PROP_FIRST_GOAL_SCORED_TEAM = "PLAYER_PROP_FIRST_GOAL_SCORED_TEAM",
  PLAYER_PROP_LAST_GOAL_SCORED = "PLAYER_PROP_LAST_GOAL_SCORED",
  PLAYER_PROP_LAST_GOAL_SCORED_FIRST_QUARTER = "PLAYER_PROP_LAST_GOAL_SCORED_FIRST_QUARTER",
  PLAYER_PROP_LAST_GOAL_SCORED_SECOND_QUARTER = "PLAYER_PROP_LAST_GOAL_SCORED_SECOND_QUARTER",
  PLAYER_PROP_LAST_GOAL_SCORED_THIRD_QUARTER = "PLAYER_PROP_LAST_GOAL_SCORED_THIRD_QUARTER",
  PLAYER_PROP_FIRST_POINT = "PLAYER_PROP_FIRST_POINT",
  PLAYER_PROP_FIRST_POINT_TEAM = "PLAYER_PROP_FIRST_POINT_TEAM",
  PLAYER_PROP_FIRST_FIELD_GOAL = "PLAYER_PROP_FIRST_FIELD_GOAL",
  PLAYER_PROP_FIRST_FIELD_GOAL_TEAM = "PLAYER_PROP_FIRST_FIELD_GOAL_TEAM",
  PLAYER_PROP_FIRST_POINT_SECOND_HALF = "PLAYER_PROP_FIRST_POINT_SECOND_HALF",
  PLAYER_PROP_FIRST_POINT_TEAM_SECOND_HALF = "PLAYER_PROP_FIRST_POINT_TEAM_SECOND_HALF",
  PLAYER_PROP_FIRST_POINT_EXACT_MULTI = "PLAYER_PROP_FIRST_POINT_EXACT_MULTI",
  PLAYER_PROP_FIRST_POINT_TYPE_MULTI = "PLAYER_PROP_FIRST_POINT_TYPE_MULTI",
  PLAYER_PROP_FIRST_TWO_POINTER = "PLAYER_PROP_FIRST_TWO_POINTER",
  PLAYER_PROP_FIRST_REBOUND = "PLAYER_PROP_FIRST_REBOUND",
  PLAYER_PROP_FIRST_ASSIST = "PLAYER_PROP_FIRST_ASSIST",
  PLAYER_PROP_FIRST_FREE_THROW = "PLAYER_PROP_FIRST_FREE_THROW",
  PLAYER_PROP_FIRST_THREE_POINTER = "PLAYER_PROP_FIRST_THREE_POINTER",
  PLAYER_PROP_FIRST_THREE_POINTER_TEAM = "PLAYER_PROP_FIRST_THREE_POINTER_TEAM",
  PLAYER_PROP_MOST_POINTS = "PLAYER_PROP_MOST_POINTS",
  PLAYER_PROP_MOST_POINTS_TEAM = "PLAYER_PROP_MOST_POINTS_TEAM",
  PLAYER_PROP_MOST_REBOUNDS = "PLAYER_PROP_MOST_REBOUNDS",
  PLAYER_PROP_MOST_ASSISTS = "PLAYER_PROP_MOST_ASSISTS",
  PLAYER_PROP_MOST_FREE_THROW = "PLAYER_PROP_MOST_FREE_THROW",
  PLAYER_PROP_MOST_TWO_POINTERS = "PLAYER_PROP_MOST_TWO_POINTERS",
  PLAYER_PROP_MOST_THREE_POINTERS = "PLAYER_PROP_MOST_THREE_POINTERS",
  PLAYER_PROP_MOST_TURNOVERS = "PLAYER_PROP_MOST_TURNOVERS",
  PLAYER_PROP_MOST_STEALS = "PLAYER_PROP_MOST_STEALS",
  PLAYER_PROP_MOST_BLOCKS = "PLAYER_PROP_MOST_BLOCKS",
  PLAYER_PROP_MOST_SHOTS_ON_GOAL = "PLAYER_PROP_MOST_SHOTS_ON_GOAL",
  PLAYER_PROP_FOULS = "PLAYER_PROP_FOULS",
  PLAYER_PROP_PLUS_MINUS = "PLAYER_PROP_PLUS_MINUS",
  MONEY_LINE = "MONEY_LINE",
  MONEY_LINE_3_WAY = "MONEY_LINE_3_WAY",
  MONEY_LINE_NO_OT = "MONEY_LINE_NO_OT",
  MONEY_LINE_NO_OT_3_WAY = "MONEY_LINE_NO_OT_3_WAY",
  MONEY_LINE_FIRST_HALF = "MONEY_LINE_FIRST_HALF",
  MONEY_LINE_FIRST_HALF_3_WAY = "MONEY_LINE_FIRST_HALF_3_WAY",
  MONEY_LINE_SECOND_HALF = "MONEY_LINE_SECOND_HALF",
  MONEY_LINE_SECOND_HALF_NO_OT = "MONEY_LINE_SECOND_HALF_NO_OT",
  MONEY_LINE_SECOND_HALF_NO_OT_3_WAY = "MONEY_LINE_SECOND_HALF_NO_OT_3_WAY",
  MONEY_LINE_SECOND_HALF_3_WAY = "MONEY_LINE_SECOND_HALF_3_WAY",
  MONEY_LINE_FIRST_QUARTER = "MONEY_LINE_FIRST_QUARTER",
  MONEY_LINE_FIRST_QUARTER_3_WAY = "MONEY_LINE_FIRST_QUARTER_3_WAY",
  MONEY_LINE_SECOND_QUARTER = "MONEY_LINE_SECOND_QUARTER",
  MONEY_LINE_SECOND_QUARTER_3_WAY = "MONEY_LINE_SECOND_QUARTER_3_WAY",
  MONEY_LINE_THIRD_QUARTER = "MONEY_LINE_THIRD_QUARTER",
  MONEY_LINE_THIRD_QUARTER_3_WAY = "MONEY_LINE_THIRD_QUARTER_3_WAY",
  MONEY_LINE_FOURTH_QUARTER = "MONEY_LINE_FOURTH_QUARTER",
  MONEY_LINE_FOURTH_QUARTER_NO_OT = "MONEY_LINE_FOURTH_QUARTER_NO_OT",
  MONEY_LINE_FOURTH_QUARTER_3_WAY = "MONEY_LINE_FOURTH_QUARTER_3_WAY",
  MONEY_LINE_TO_QUALIFY = "MONEY_LINE_TO_QUALIFY",
  MONEY_LINE_FIRST_INNING = "MONEY_LINE_FIRST_INNING",
  MONEY_LINE_SECOND_INNING = "MONEY_LINE_SECOND_INNING",
  MONEY_LINE_THIRD_INNING = "MONEY_LINE_THIRD_INNING",
  MONEY_LINE_FOURTH_INNING = "MONEY_LINE_FOURTH_INNING",
  MONEY_LINE_FIFTH_INNING = "MONEY_LINE_FIFTH_INNING",
  MONEY_LINE_SIXTH_INNING = "MONEY_LINE_SIXTH_INNING",
  MONEY_LINE_SEVENTH_INNING = "MONEY_LINE_SEVENTH_INNING",
  MONEY_LINE_EIGHTH_INNING = "MONEY_LINE_EIGHTH_INNING",
  MONEY_LINE_NINTH_INNING = "MONEY_LINE_NINTH_INNING",
  MONEY_LINE_TENTH_INNING = "MONEY_LINE_TENTH_INNING",
  MONEY_LINE_FIRST_INNING_3_WAY = "MONEY_LINE_FIRST_INNING_3_WAY",
  MONEY_LINE_SECOND_INNING_3_WAY = "MONEY_LINE_SECOND_INNING_3_WAY",
  MONEY_LINE_THIRD_INNING_3_WAY = "MONEY_LINE_THIRD_INNING_3_WAY",
  MONEY_LINE_FOURTH_INNING_3_WAY = "MONEY_LINE_FOURTH_INNING_3_WAY",
  MONEY_LINE_FIFTH_INNING_3_WAY = "MONEY_LINE_FIFTH_INNING_3_WAY",
  MONEY_LINE_SIXTH_INNING_3_WAY = "MONEY_LINE_SIXTH_INNING_3_WAY",
  MONEY_LINE_SEVENTH_INNING_3_WAY = "MONEY_LINE_SEVENTH_INNING_3_WAY",
  MONEY_LINE_EIGHTH_INNING_3_WAY = "MONEY_LINE_EIGHTH_INNING_3_WAY",
  MONEY_LINE_NINTH_INNING_3_WAY = "MONEY_LINE_NINTH_INNING_3_WAY",
  MONEY_LINE_TENTH_INNING_3_WAY = "MONEY_LINE_TENTH_INNING_3_WAY",
  MONEY_LINE_FIRST_SET = "MONEY_LINE_FIRST_SET",
  MONEY_LINE_SECOND_SET = "MONEY_LINE_SECOND_SET",
  MONEY_LINE_THIRD_SET = "MONEY_LINE_THIRD_SET",
  MONEY_LINE_FOURTH_SET = "MONEY_LINE_FOURTH_SET",
  MONEY_LINE_FIFTH_SET = "MONEY_LINE_FIFTH_SET",
  MONEY_LINE_GAME_FIRST_SET = "MONEY_LINE_GAME_FIRST_SET",
  MONEY_LINE_GAME_SECOND_SET = "MONEY_LINE_GAME_SECOND_SET",
  MONEY_LINE_GAME_THIRD_SET = "MONEY_LINE_GAME_THIRD_SET",
  MONEY_LINE_GAME_FOURTH_SET = "MONEY_LINE_GAME_FOURTH_SET",
  MONEY_LINE_GAME_FIFTH_SET = "MONEY_LINE_GAME_FIFTH_SET",
  MONEY_LINE_GAME_POINT_FIRST_SET = "MONEY_LINE_GAME_POINT_FIRST_SET",
  MONEY_LINE_GAME_POINT_SECOND_SET = "MONEY_LINE_GAME_POINT_SECOND_SET",
  MONEY_LINE_GAME_POINT_THIRD_SET = "MONEY_LINE_GAME_POINT_THIRD_SET",
  MONEY_LINE_GAME_POINT_FOURTH_SET = "MONEY_LINE_GAME_POINT_FOURTH_SET",
  MONEY_LINE_GAME_POINT_FIFTH_SET = "MONEY_LINE_GAME_POINT_FIFTH_SET",
  TOTAL_GAME_POINTS = "TOTAL_GAME_POINTS",
  TOTAL_GAME_POINTS_NO_OT = "TOTAL_GAME_POINTS_NO_OT",
  TOTAL_GAME_POINTS_3_WAY = "TOTAL_GAME_POINTS_3_WAY",
  TOTAL_GAME_POINTS_FIRST_HALF = "TOTAL_GAME_POINTS_FIRST_HALF",
  TOTAL_GAME_POINTS_SECOND_HALF = "TOTAL_GAME_POINTS_SECOND_HALF",
  TOTAL_GAME_POINTS_SECOND_HALF_NO_OT = "TOTAL_GAME_POINTS_SECOND_HALF_NO_OT",
  TOTAL_GAME_POINTS_FIRST_QUARTER = "TOTAL_GAME_POINTS_FIRST_QUARTER",
  TOTAL_GAME_POINTS_SECOND_QUARTER = "TOTAL_GAME_POINTS_SECOND_QUARTER",
  TOTAL_GAME_POINTS_THIRD_QUARTER = "TOTAL_GAME_POINTS_THIRD_QUARTER",
  TOTAL_GAME_POINTS_FOURTH_QUARTER = "TOTAL_GAME_POINTS_FOURTH_QUARTER",
  TOTAL_GAME_POINTS_FOURTH_QUARTER_NO_OT = "TOTAL_GAME_POINTS_FOURTH_QUARTER_NO_OT",
  TOTAL_GAME_POINTS_FIRST_INNING = "TOTAL_GAME_POINTS_FIRST_INNING",
  TOTAL_GAME_POINTS_SECOND_INNING = "TOTAL_GAME_POINTS_SECOND_INNING",
  TOTAL_GAME_POINTS_THIRD_INNING = "TOTAL_GAME_POINTS_THIRD_INNING",
  TOTAL_GAME_POINTS_FOURTH_INNING = "TOTAL_GAME_POINTS_FOURTH_INNING",
  TOTAL_GAME_POINTS_FIFTH_INNING = "TOTAL_GAME_POINTS_FIFTH_INNING",
  TOTAL_GAME_POINTS_SIXTH_INNING = "TOTAL_GAME_POINTS_SIXTH_INNING",
  TOTAL_GAME_POINTS_SEVENTH_INNING = "TOTAL_GAME_POINTS_SEVENTH_INNING",
  TOTAL_GAME_POINTS_EIGHTH_INNING = "TOTAL_GAME_POINTS_EIGHTH_INNING",
  TOTAL_GAME_POINTS_NINTH_INNING = "TOTAL_GAME_POINTS_NINTH_INNING",
  TOTAL_GAME_POINTS_TENTH_INNING = "TOTAL_GAME_POINTS_TENTH_INNING",
  TOTAL_GAME_TOUCHDOWNS = "TOTAL_GAME_TOUCHDOWNS",
  TOTAL_GAME_TOUCHDOWNS_NO_OT = "TOTAL_GAME_TOUCHDOWNS_NO_OT",
  TOTAL_GAME_TOUCHDOWNS_FIRST_HALF = "TOTAL_GAME_TOUCHDOWNS_FIRST_HALF",
  TOTAL_GAME_TOUCHDOWNS_SECOND_HALF = "TOTAL_GAME_TOUCHDOWNS_SECOND_HALF",
  TOTAL_GAME_TOUCHDOWNS_FIRST_QUARTER = "TOTAL_GAME_TOUCHDOWNS_FIRST_QUARTER",
  TOTAL_GAME_TOUCHDOWNS_SECOND_QUARTER = "TOTAL_GAME_TOUCHDOWNS_SECOND_QUARTER",
  TOTAL_GAME_TOUCHDOWNS_THIRD_QUARTER = "TOTAL_GAME_TOUCHDOWNS_THIRD_QUARTER",
  TOTAL_GAME_TOUCHDOWNS_FOURTH_QUARTER = "TOTAL_GAME_TOUCHDOWNS_FOURTH_QUARTER",
  TOTAL_GAME_FIRST_TOUCHDOWN_YARDS = "TOTAL_GAME_FIRST_TOUCHDOWN_YARDS",
  TOTAL_GAME_TOUCHDOWN_YARDS = "TOTAL_GAME_TOUCHDOWN_YARDS",
  TOTAL_GAME_SHORTEST_TOUCHDOWN_YARDS = "TOTAL_GAME_SHORTEST_TOUCHDOWN_YARDS",
  TOTAL_GAME_LONGEST_TOUCHDOWN_YARDS = "TOTAL_GAME_LONGEST_TOUCHDOWN_YARDS",
  TOTAL_GAME_TURNOVERS = "TOTAL_GAME_TURNOVERS",
  TOTAL_GAME_FIELD_GOAL_YARDS = "TOTAL_GAME_FIELD_GOAL_YARDS",
  TOTAL_GAME_SHORTEST_FIELD_GOAL_YARDS = "TOTAL_GAME_SHORTEST_FIELD_GOAL_YARDS",
  TOTAL_GAME_LONGEST_FIELD_GOAL_YARDS = "TOTAL_GAME_LONGEST_FIELD_GOAL_YARDS",
  TOTAL_GAME_SHOTS = "TOTAL_GAME_SHOTS",
  TOTAL_GAME_SHOTS_ON_GOAL = "TOTAL_GAME_SHOTS_ON_GOAL",
  TOTAL_GAME_SHOTS_ON_GOAL_SPREAD = "TOTAL_GAME_SHOTS_ON_GOAL_SPREAD",
  TOTAL_GAME_SHOTS_ON_GOAL_FIRST_HALF = "TOTAL_GAME_SHOTS_ON_GOAL_FIRST_HALF",
  TOTAL_GAME_SHOTS_ON_GOAL_SECOND_HALF_NO_OT = "TOTAL_GAME_SHOTS_ON_GOAL_SECOND_HALF_NO_OT",
  TOTAL_GAME_POWER_PLAY_GOALS = "TOTAL_GAME_POWER_PLAY_GOALS",
  TOTAL_GAME_SHORT_HANDED_GOALS = "TOTAL_GAME_SHORT_HANDED_GOALS",
  TOTAL_GAME_SACKS = "TOTAL_GAME_SACKS",
  TOTAL_GAME_FIELD_GOALS_MADE = "TOTAL_GAME_FIELD_GOALS_MADE",
  TOTAL_GAME_FIELD_GOALS_MADE_FIRST_HALF = "TOTAL_GAME_FIELD_GOALS_MADE_FIRST_HALF",
  TOTAL_GAME_FIELD_GOALS_MADE_SECOND_HALF = "TOTAL_GAME_FIELD_GOALS_MADE_SECOND_HALF",
  TOTAL_GAME_FIELD_GOALS_MADE_FIRST_QUARTER = "TOTAL_GAME_FIELD_GOALS_MADE_FIRST_QUARTER",
  TOTAL_GAME_FIELD_GOALS_MADE_SECOND_QUARTER = "TOTAL_GAME_FIELD_GOALS_MADE_SECOND_QUARTER",
  TOTAL_GAME_FIELD_GOALS_MADE_THIRD_QUARTER = "TOTAL_GAME_FIELD_GOALS_MADE_THIRD_QUARTER",
  TOTAL_GAME_FIELD_GOALS_MADE_FOURTH_QUARTER = "TOTAL_GAME_FIELD_GOALS_MADE_FOURTH_QUARTER",
  TOTAL_GAME_PUNTS = "TOTAL_GAME_PUNTS",
  TOTAL_GAME_CARDS = "TOTAL_GAME_CARDS",
  TOTAL_GAME_CARDS_FIRST_HALF = "TOTAL_GAME_CARDS_FIRST_HALF",
  TOTAL_GAME_CARDS_SECOND_HALF = "TOTAL_GAME_CARDS_SECOND_HALF",
  TOTAL_GAME_CARDS_SECOND_HALF_NO_OT = "TOTAL_GAME_CARDS_SECOND_HALF_NO_OT",
  TOTAL_GAME_YELLOW_CARDS = "TOTAL_GAME_YELLOW_CARDS",
  TOTAL_GAME_YELLOW_CARDS_FIRST_HALF = "TOTAL_GAME_YELLOW_CARDS_FIRST_HALF",
  TOTAL_GAME_YELLOW_CARDS_SECOND_HALF = "TOTAL_GAME_YELLOW_CARDS_SECOND_HALF",
  TOTAL_GAME_CORNERS = "TOTAL_GAME_CORNERS",
  TOTAL_GAME_CORNERS_FIRST_HALF = "TOTAL_GAME_CORNERS_FIRST_HALF",
  TOTAL_GAME_CORNERS_SECOND_HALF = "TOTAL_GAME_CORNERS_SECOND_HALF",
  TOTAL_GAME_FOULS_CONCEDED = "TOTAL_GAME_FOULS_CONCEDED",
  TOTAL_GAME_OFFSIDES = "TOTAL_GAME_OFFSIDES",
  TOTAL_GAME_OFFSIDES_FIRST_HALF = "TOTAL_GAME_OFFSIDES_FIRST_HALF",
  TOTAL_GAME_OFFSIDES_SECOND_HALF = "TOTAL_GAME_OFFSIDES_SECOND_HALF",
  TOTAL_GAME_OFFSIDES_SPREAD = "TOTAL_GAME_OFFSIDES_SPREAD",
  TOTAL_GAME_HOME_RUNS = "TOTAL_GAME_HOME_RUNS",
  TOTAL_GAME_BASES = "TOTAL_GAME_BASES",
  TOTAL_GAME_BASES_STOLEN = "TOTAL_GAME_BASES_STOLEN",
  TOTAL_GAME_STRIKEOUTS = "TOTAL_GAME_STRIKEOUTS",
  TOTAL_GAME_WALKS = "TOTAL_GAME_WALKS",
  TOTAL_GAME_HITS = "TOTAL_GAME_HITS",
  TOTAL_GAME_HITS_FIRST_HALF = "TOTAL_GAME_HITS_FIRST_HALF",
  TOTAL_GAME_HITS_SECOND_HALF = "TOTAL_GAME_HITS_SECOND_HALF",
  TOTAL_GAME_HITS_FIRST_INNING = "TOTAL_GAME_HITS_FIRST_INNING",
  TOTAL_GAME_HITS_SECOND_INNING = "TOTAL_GAME_HITS_SECOND_INNING",
  TOTAL_GAME_HITS_THIRD_INNING = "TOTAL_GAME_HITS_THIRD_INNING",
  TOTAL_GAME_HITS_FOURTH_INNING = "TOTAL_GAME_HITS_FOURTH_INNING",
  TOTAL_GAME_HITS_FIFTH_INNING = "TOTAL_GAME_HITS_FIFTH_INNING",
  TOTAL_GAME_HITS_SIXTH_INNING = "TOTAL_GAME_HITS_SIXTH_INNING",
  TOTAL_GAME_HITS_SEVENTH_INNING = "TOTAL_GAME_HITS_SEVENTH_INNING",
  TOTAL_GAME_HITS_EIGHTH_INNING = "TOTAL_GAME_HITS_EIGHTH_INNING",
  TOTAL_GAME_HITS_NINTH_INNING = "TOTAL_GAME_HITS_NINTH_INNING",
  TOTAL_GAME_HITS_TENTH_INNING = "TOTAL_GAME_HITS_TENTH_INNING",
  TOTAL_GAME_SINGLE = "TOTAL_GAME_SINGLE",
  TOTAL_GAME_DOUBLE = "TOTAL_GAME_DOUBLE",
  TOTAL_GAME_TRIPLE = "TOTAL_GAME_TRIPLE",
  TOTAL_GAME_RB_IS = "TOTAL_GAME_RB_IS",
  TOTAL_GAME_SETS = "TOTAL_GAME_SETS",
  TOTAL_GAME_GAMES = "TOTAL_GAME_GAMES",
  TOTAL_GAME_GAMES_3_WAY = "TOTAL_GAME_GAMES_3_WAY",
  TOTAL_GAME_GAMES_FIRST_SET_3_WAY = "TOTAL_GAME_GAMES_FIRST_SET_3_WAY",
  TOTAL_GAME_GAMES_SECOND_SET_3_WAY = "TOTAL_GAME_GAMES_SECOND_SET_3_WAY",
  TOTAL_GAME_GAMES_THIRD_SET_3_WAY = "TOTAL_GAME_GAMES_THIRD_SET_3_WAY",
  TOTAL_GAME_GAMES_FOURTH_SET_3_WAY = "TOTAL_GAME_GAMES_FOURTH_SET_3_WAY",
  TOTAL_GAME_GAMES_FIFTH_SET_3_WAY = "TOTAL_GAME_GAMES_FIFTH_SET_3_WAY",
  TOTAL_GAME_GAMES_FIRST_SET = "TOTAL_GAME_GAMES_FIRST_SET",
  TOTAL_GAME_GAMES_SECOND_SET = "TOTAL_GAME_GAMES_SECOND_SET",
  TOTAL_GAME_GAMES_THIRD_SET = "TOTAL_GAME_GAMES_THIRD_SET",
  TOTAL_GAME_GAMES_FOURTH_SET = "TOTAL_GAME_GAMES_FOURTH_SET",
  TOTAL_GAME_GAMES_FIFTH_SET = "TOTAL_GAME_GAMES_FIFTH_SET",
  TOTAL_GAME_DOUBLE_FAULTS = "TOTAL_GAME_DOUBLE_FAULTS",
  TOTAL_GAME_ACES = "TOTAL_GAME_ACES",
  TOTAL_GAME_SERVICE_BREAKS = "TOTAL_GAME_SERVICE_BREAKS",
  TOTAL_GAME_THREE_POINTERS = "TOTAL_GAME_THREE_POINTERS",
  TOTAL_GAME_MAXIMUM_CONSECUTIVE_POINTS = "TOTAL_GAME_MAXIMUM_CONSECUTIVE_POINTS",
  TOTAL_GAME_LEAD_BY_POINTS = "TOTAL_GAME_LEAD_BY_POINTS",
  TOTAL_GAME_POINTS_TEAM = "TOTAL_GAME_POINTS_TEAM",
  TOTAL_GAME_POINTS_TEAM_NO_OT = "TOTAL_GAME_POINTS_TEAM_NO_OT",
  TOTAL_GAME_POINTS_TEAM_FIRST_HALF = "TOTAL_GAME_POINTS_TEAM_FIRST_HALF",
  TOTAL_GAME_POINTS_TEAM_SECOND_HALF = "TOTAL_GAME_POINTS_TEAM_SECOND_HALF",
  TOTAL_GAME_POINTS_TEAM_SECOND_HALF_NO_OT = "TOTAL_GAME_POINTS_TEAM_SECOND_HALF_NO_OT",
  TOTAL_GAME_POINTS_TEAM_FIRST_QUARTER = "TOTAL_GAME_POINTS_TEAM_FIRST_QUARTER",
  TOTAL_GAME_POINTS_TEAM_SECOND_QUARTER = "TOTAL_GAME_POINTS_TEAM_SECOND_QUARTER",
  TOTAL_GAME_POINTS_TEAM_THIRD_QUARTER = "TOTAL_GAME_POINTS_TEAM_THIRD_QUARTER",
  TOTAL_GAME_POINTS_TEAM_FOURTH_QUARTER = "TOTAL_GAME_POINTS_TEAM_FOURTH_QUARTER",
  TOTAL_GAME_POINTS_TEAM_FIRST_QUARTER_EXACT = "TOTAL_GAME_POINTS_TEAM_FIRST_QUARTER_EXACT",
  TOTAL_GAME_POINTS_TEAM_SECOND_QUARTER_EXACT = "TOTAL_GAME_POINTS_TEAM_SECOND_QUARTER_EXACT",
  TOTAL_GAME_POINTS_TEAM_THIRD_QUARTER_EXACT = "TOTAL_GAME_POINTS_TEAM_THIRD_QUARTER_EXACT",
  TOTAL_GAME_POINTS_TEAM_FOURTH_QUARTER_EXACT = "TOTAL_GAME_POINTS_TEAM_FOURTH_QUARTER_EXACT",
  TOTAL_GAME_POINTS_TEAM_FIRST_INNING = "TOTAL_GAME_POINTS_TEAM_FIRST_INNING",
  TOTAL_GAME_POINTS_TEAM_SECOND_INNING = "TOTAL_GAME_POINTS_TEAM_SECOND_INNING",
  TOTAL_GAME_POINTS_TEAM_THIRD_INNING = "TOTAL_GAME_POINTS_TEAM_THIRD_INNING",
  TOTAL_GAME_POINTS_TEAM_FOURTH_INNING = "TOTAL_GAME_POINTS_TEAM_FOURTH_INNING",
  TOTAL_GAME_POINTS_TEAM_FIFTH_INNING = "TOTAL_GAME_POINTS_TEAM_FIFTH_INNING",
  TOTAL_GAME_POINTS_TEAM_SIXTH_INNING = "TOTAL_GAME_POINTS_TEAM_SIXTH_INNING",
  TOTAL_GAME_POINTS_TEAM_SEVENTH_INNING = "TOTAL_GAME_POINTS_TEAM_SEVENTH_INNING",
  TOTAL_GAME_POINTS_TEAM_EIGHTH_INNING = "TOTAL_GAME_POINTS_TEAM_EIGHTH_INNING",
  TOTAL_GAME_POINTS_TEAM_NINTH_INNING = "TOTAL_GAME_POINTS_TEAM_NINTH_INNING",
  TOTAL_GAME_POINTS_TEAM_TENTH_INNING = "TOTAL_GAME_POINTS_TEAM_TENTH_INNING",
  TOTAL_GAME_TOUCHDOWNS_TEAM = "TOTAL_GAME_TOUCHDOWNS_TEAM",
  TOTAL_GAME_TOUCHDOWNS_TEAM_FIRST_HALF = "TOTAL_GAME_TOUCHDOWNS_TEAM_FIRST_HALF",
  TOTAL_GAME_TOUCHDOWNS_TEAM_SECOND_HALF = "TOTAL_GAME_TOUCHDOWNS_TEAM_SECOND_HALF",
  TOTAL_GAME_TOUCHDOWNS_TEAM_SECOND_HALF_NO_OT = "TOTAL_GAME_TOUCHDOWNS_TEAM_SECOND_HALF_NO_OT",
  TOTAL_GAME_TOUCHDOWNS_TEAM_FIRST_QUARTER = "TOTAL_GAME_TOUCHDOWNS_TEAM_FIRST_QUARTER",
  TOTAL_GAME_TOUCHDOWNS_TEAM_SECOND_QUARTER = "TOTAL_GAME_TOUCHDOWNS_TEAM_SECOND_QUARTER",
  TOTAL_GAME_TOUCHDOWNS_TEAM_THIRD_QUARTER = "TOTAL_GAME_TOUCHDOWNS_TEAM_THIRD_QUARTER",
  TOTAL_GAME_TOUCHDOWNS_TEAM_FOURTH_QUARTER = "TOTAL_GAME_TOUCHDOWNS_TEAM_FOURTH_QUARTER",
  TOTAL_GAME_FIELD_GOALS_MADE_TEAM = "TOTAL_GAME_FIELD_GOALS_MADE_TEAM",
  TOTAL_GAME_FIELD_GOALS_MADE_TEAM_FIRST_HALF = "TOTAL_GAME_FIELD_GOALS_MADE_TEAM_FIRST_HALF",
  TOTAL_GAME_FIELD_GOALS_MADE_TEAM_SECOND_HALF = "TOTAL_GAME_FIELD_GOALS_MADE_TEAM_SECOND_HALF",
  TOTAL_GAME_FIELD_GOALS_MADE_TEAM_FIRST_QUARTER = "TOTAL_GAME_FIELD_GOALS_MADE_TEAM_FIRST_QUARTER",
  TOTAL_GAME_FIELD_GOALS_MADE_TEAM_SECOND_QUARTER = "TOTAL_GAME_FIELD_GOALS_MADE_TEAM_SECOND_QUARTER",
  TOTAL_GAME_FIELD_GOALS_MADE_TEAM_THIRD_QUARTER = "TOTAL_GAME_FIELD_GOALS_MADE_TEAM_THIRD_QUARTER",
  TOTAL_GAME_FIELD_GOALS_MADE_TEAM_FOURTH_QUARTER = "TOTAL_GAME_FIELD_GOALS_MADE_TEAM_FOURTH_QUARTER",
  TOTAL_GAME_SACKS_TEAM = "TOTAL_GAME_SACKS_TEAM",
  TOTAL_GAME_SAFTEY_TEAM = "TOTAL_GAME_SAFTEY_TEAM",
  TOTAL_GAME_FUMBLE_TEAM = "TOTAL_GAME_FUMBLE_TEAM",
  TOTAL_GAME_CARDS_TEAM = "TOTAL_GAME_CARDS_TEAM",
  TOTAL_GAME_CARDS_TEAM_FIRST_HALF = "TOTAL_GAME_CARDS_TEAM_FIRST_HALF",
  TOTAL_GAME_CARDS_TEAM_SECOND_HALF = "TOTAL_GAME_CARDS_TEAM_SECOND_HALF",
  TOTAL_GAME_YELLOW_CARDS_TEAM = "TOTAL_GAME_YELLOW_CARDS_TEAM",
  TOTAL_GAME_YELLOW_CARDS_TEAM_FIRST_HALF = "TOTAL_GAME_YELLOW_CARDS_TEAM_FIRST_HALF",
  TOTAL_GAME_YELLOW_CARDS_TEAM_SECOND_HALF = "TOTAL_GAME_YELLOW_CARDS_TEAM_SECOND_HALF",
  TOTAL_GAME_CORNERS_TEAM = "TOTAL_GAME_CORNERS_TEAM",
  TOTAL_GAME_CORNERS_TEAM_FIRST_HALF = "TOTAL_GAME_CORNERS_TEAM_FIRST_HALF",
  TOTAL_GAME_CORNERS_TEAM_SECOND_HALF = "TOTAL_GAME_CORNERS_TEAM_SECOND_HALF",
  TOTAL_GAME_FOULS_CONCEDED_TEAM = "TOTAL_GAME_FOULS_CONCEDED_TEAM",
  TOTAL_GAME_POWER_PLAY_GOALS_TEAM = "TOTAL_GAME_POWER_PLAY_GOALS_TEAM",
  TOTAL_GAME_SHOTS_ON_GOAL_TEAM = "TOTAL_GAME_SHOTS_ON_GOAL_TEAM",
  TOTAL_GAME_SHOTS_TEAM = "TOTAL_GAME_SHOTS_TEAM",
  TOTAL_GAME_OFFSIDES_TEAM = "TOTAL_GAME_OFFSIDES_TEAM",
  TOTAL_GAME_SHOTS_SAVED_TEAM = "TOTAL_GAME_SHOTS_SAVED_TEAM",
  TOTAL_GAME_BLOCKS_TEAM = "TOTAL_GAME_BLOCKS_TEAM",
  TOTAL_GAME_STEALS_TEAM = "TOTAL_GAME_STEALS_TEAM",
  TOTAL_GAME_ASSISTS_TEAM = "TOTAL_GAME_ASSISTS_TEAM",
  TOTAL_GAME_REBOUNDS_TEAM = "TOTAL_GAME_REBOUNDS_TEAM",
  TOTAL_GAME_THREES_TEAM = "TOTAL_GAME_THREES_TEAM",
  TOTAL_GAME_BASES_TEAM = "TOTAL_GAME_BASES_TEAM",
  TOTAL_GAME_BASES_STOLEN_TEAM = "TOTAL_GAME_BASES_STOLEN_TEAM",
  TOTAL_GAME_SINGLES_TEAM = "TOTAL_GAME_SINGLES_TEAM",
  TOTAL_GAME_DOUBLES_TEAM = "TOTAL_GAME_DOUBLES_TEAM",
  TOTAL_GAME_TRIPLES_TEAM = "TOTAL_GAME_TRIPLES_TEAM",
  TOTAL_GAME_HIT_TEAM = "TOTAL_GAME_HIT_TEAM",
  TOTAL_GAME_HOME_RUN_TEAM = "TOTAL_GAME_HOME_RUN_TEAM",
  TOTAL_GAME_STRIKEOUTS_TEAM = "TOTAL_GAME_STRIKEOUTS_TEAM",
  TOTAL_GAME_GAMES_TEAM = "TOTAL_GAME_GAMES_TEAM",
  TOTAL_GAME_ACES_TEAM = "TOTAL_GAME_ACES_TEAM",
  TOTAL_GAME_DOUBLE_FAULTS_TEAM = "TOTAL_GAME_DOUBLE_FAULTS_TEAM",
  TOTAL_GAME_SERVICE_BREAKS_TEAM = "TOTAL_GAME_SERVICE_BREAKS_TEAM",
  TOTAL_GAME_LARGEST_LEAD = "TOTAL_GAME_LARGEST_LEAD",
  TOTAL_GAME_MAXIMUM_CONSECUTIVE_POINTS_TEAM = "TOTAL_GAME_MAXIMUM_CONSECUTIVE_POINTS_TEAM",
  TOTAL_GAME_LEAD_BY_POINTS_TEAM = "TOTAL_GAME_LEAD_BY_POINTS_TEAM",
  SPREAD = "SPREAD",
  SPREAD_3_WAY = "SPREAD_3_WAY",
  SPREAD_NO_OT = "SPREAD_NO_OT",
  SPREAD_NO_OT_3_WAY = "SPREAD_NO_OT_3_WAY",
  SPREAD_FIRST_HALF = "SPREAD_FIRST_HALF",
  SPREAD_FIRST_HALF_3_WAY = "SPREAD_FIRST_HALF_3_WAY",
  SPREAD_SECOND_HALF = "SPREAD_SECOND_HALF",
  SPREAD_SECOND_HALF_NO_OT = "SPREAD_SECOND_HALF_NO_OT",
  SPREAD_SECOND_HALF_NO_OT_3_WAY = "SPREAD_SECOND_HALF_NO_OT_3_WAY",
  SPREAD_FIRST_QUARTER = "SPREAD_FIRST_QUARTER",
  SPREAD_FIRST_QUARTER_3_WAY = "SPREAD_FIRST_QUARTER_3_WAY",
  SPREAD_SECOND_QUARTER = "SPREAD_SECOND_QUARTER",
  SPREAD_SECOND_QUARTER_3_WAY = "SPREAD_SECOND_QUARTER_3_WAY",
  SPREAD_THIRD_QUARTER = "SPREAD_THIRD_QUARTER",
  SPREAD_THIRD_QUARTER_3_WAY = "SPREAD_THIRD_QUARTER_3_WAY",
  SPREAD_FOURTH_QUARTER = "SPREAD_FOURTH_QUARTER",
  SPREAD_FOURTH_QUARTER_NO_OT = "SPREAD_FOURTH_QUARTER_NO_OT",
  SPREAD_FOURTH_QUARTER_3_WAY = "SPREAD_FOURTH_QUARTER_3_WAY",
  SPREAD_CORNERS = "SPREAD_CORNERS",
  SPREAD_CORNERS_FIRST_HALF = "SPREAD_CORNERS_FIRST_HALF",
  SPREAD_CORNERS_SECOND_HALF = "SPREAD_CORNERS_SECOND_HALF",
  SPREAD_CARDS = "SPREAD_CARDS",
  SPREAD_CARDS_FIRST_HALF = "SPREAD_CARDS_FIRST_HALF",
  SPREAD_CARDS_SECOND_HALF = "SPREAD_CARDS_SECOND_HALF",
  SPREAD_CARDS_3_WAY = "SPREAD_CARDS_3_WAY",
  SPREAD_FIRST_INNING = "SPREAD_FIRST_INNING",
  SPREAD_SECOND_INNING = "SPREAD_SECOND_INNING",
  SPREAD_THIRD_INNING = "SPREAD_THIRD_INNING",
  SPREAD_FOURTH_INNING = "SPREAD_FOURTH_INNING",
  SPREAD_FIFTH_INNING = "SPREAD_FIFTH_INNING",
  SPREAD_SIXTH_INNING = "SPREAD_SIXTH_INNING",
  SPREAD_SEVENTH_INNING = "SPREAD_SEVENTH_INNING",
  SPREAD_EIGHTH_INNING = "SPREAD_EIGHTH_INNING",
  SPREAD_NINTH_INNING = "SPREAD_NINTH_INNING",
  SPREAD_TENTH_INNING = "SPREAD_TENTH_INNING",
  SPREAD_FIRST_SET = "SPREAD_FIRST_SET",
  SPREAD_FIRST_SET_3_WAY = "SPREAD_FIRST_SET_3_WAY",
  SPREAD_SECOND_SET = "SPREAD_SECOND_SET",
  SPREAD_SECOND_SET_3_WAY = "SPREAD_SECOND_SET_3_WAY",
  SPREAD_THIRD_SET = "SPREAD_THIRD_SET",
  SPREAD_THIRD_SET_3_WAY = "SPREAD_THIRD_SET_3_WAY",
  SPREAD_FOURTH_SET = "SPREAD_FOURTH_SET",
  SPREAD_FOURTH_SET_3_WAY = "SPREAD_FOURTH_SET_3_WAY",
  SPREAD_FIFTH_SET = "SPREAD_FIFTH_SET",
  SPREAD_FIFTH_SET_3_WAY = "SPREAD_FIFTH_SET_3_WAY",
  SPREAD_GAMES = "SPREAD_GAMES",
  SPREAD_ACES = "SPREAD_ACES",
  SPREAD_DOUBLE_FAULTS = "SPREAD_DOUBLE_FAULTS",
  TO_MAKE_PLAYOFFS = "TO_MAKE_PLAYOFFS",
  WIRE_TO_WIRE_MULTI = "WIRE_TO_WIRE_MULTI",
  WINNING_MARGIN_BANDS_MULTI = "WINNING_MARGIN_BANDS_MULTI",
  DOUBLE_RESULT_MULTI = "DOUBLE_RESULT_MULTI",
  WIN_MARGIN_MULTI = "WIN_MARGIN_MULTI",
  TOTAL_GAME_POINTS_BAND_MULTI = "TOTAL_GAME_POINTS_BAND_MULTI",
  RACE_TO_POINTS = "RACE_TO_POINTS",
  RACE_TO_POINTS_NO_OT = "RACE_TO_POINTS_NO_OT",
  RACE_TO_POINTS_3_WAY = "RACE_TO_POINTS_3_WAY",
  RACE_TO_POINTS_NO_OT_3_WAY = "RACE_TO_POINTS_NO_OT_3_WAY",
  RACE_TO_POINTS_FIRST_HALF = "RACE_TO_POINTS_FIRST_HALF",
  RACE_TO_POINTS_FIRST_HALF_3_WAY = "RACE_TO_POINTS_FIRST_HALF_3_WAY",
  RACE_TO_POINTS_SECOND_HALF = "RACE_TO_POINTS_SECOND_HALF",
  RACE_TO_POINTS_SECOND_HALF_3_WAY = "RACE_TO_POINTS_SECOND_HALF_3_WAY",
  RACE_TO_POINTS_FIRST_QUARTER = "RACE_TO_POINTS_FIRST_QUARTER",
  RACE_TO_POINTS_FIRST_QUARTER_3_WAY = "RACE_TO_POINTS_FIRST_QUARTER_3_WAY",
  RACE_TO_POINTS_SECOND_QUARTER = "RACE_TO_POINTS_SECOND_QUARTER",
  RACE_TO_POINTS_SECOND_QUARTER_3_WAY = "RACE_TO_POINTS_SECOND_QUARTER_3_WAY",
  RACE_TO_POINTS_THIRD_QUARTER = "RACE_TO_POINTS_THIRD_QUARTER",
  RACE_TO_POINTS_THIRD_QUARTER_3_WAY = "RACE_TO_POINTS_THIRD_QUARTER_3_WAY",
  RACE_TO_POINTS_FOURTH_QUARTER = "RACE_TO_POINTS_FOURTH_QUARTER",
  RACE_TO_POINTS_FOURTH_QUARTER_3_WAY = "RACE_TO_POINTS_FOURTH_QUARTER_3_WAY",
  RACE_TO_POINTS_ALL_QUARTERS_3_WAY = "RACE_TO_POINTS_ALL_QUARTERS_3_WAY",
  RACE_TO_POINTS_BOTH_HALVES = "RACE_TO_POINTS_BOTH_HALVES",
  RACE_TO_CORNERS = "RACE_TO_CORNERS",
  RACE_TO_CORNERS_3_WAY = "RACE_TO_CORNERS_3_WAY",
  RACE_TO_CORNERS_FIRST_HALF = "RACE_TO_CORNERS_FIRST_HALF",
  RACE_TO_CORNERS_FIRST_HALF_3_WAY = "RACE_TO_CORNERS_FIRST_HALF_3_WAY",
  RACE_TO_CORNERS_SECOND_HALF = "RACE_TO_CORNERS_SECOND_HALF",
  RACE_TO_CORNERS_SECOND_HALF_3_WAY = "RACE_TO_CORNERS_SECOND_HALF_3_WAY",
  RACE_TO_SHOTS_ON_GOAL = "RACE_TO_SHOTS_ON_GOAL",
  TEAM_WIN_WITH_SHUTOUT = "TEAM_WIN_WITH_SHUTOUT",
  TEAM_WIN_WITH_SHUTOUT_NO_OT = "TEAM_WIN_WITH_SHUTOUT_NO_OT",
  TEAM_WIN_WITH_SHUTOUT_FIRST_HALF_NO_OT = "TEAM_WIN_WITH_SHUTOUT_FIRST_HALF_NO_OT",
  TEAM_WIN_WITH_SHUTOUT_SECOND_HALF_NO_OT = "TEAM_WIN_WITH_SHUTOUT_SECOND_HALF_NO_OT",
  TEAM_WIN_WITH_SHUTOUT_FIRST_QUARTER = "TEAM_WIN_WITH_SHUTOUT_FIRST_QUARTER",
  TEAM_WIN_WITH_SHUTOUT_SECOND_QUARTER = "TEAM_WIN_WITH_SHUTOUT_SECOND_QUARTER",
  TEAM_WIN_WITH_SHUTOUT_THIRD_QUARTER = "TEAM_WIN_WITH_SHUTOUT_THIRD_QUARTER",
  TEAM_WIN_WITH_SHUTOUT_FOURTH_QUARTER = "TEAM_WIN_WITH_SHUTOUT_FOURTH_QUARTER",
  TEAM_SCORE_BOTH_HALVES = "TEAM_SCORE_BOTH_HALVES",
  TEAM_WIN_EITHER_HALVES = "TEAM_WIN_EITHER_HALVES",
  TEAM_WIN_EITHER_HALVES_3_WAY = "TEAM_WIN_EITHER_HALVES_3_WAY",
  TEAM_WIN_BOTH_HALVES = "TEAM_WIN_BOTH_HALVES",
  TEAM_WIN_BOTH_HALVES_3_WAY = "TEAM_WIN_BOTH_HALVES_3_WAY",
  TEAM_WIN_CLEAN_SHEET = "TEAM_WIN_CLEAN_SHEET",
  TEAM_WIN_CLEAN_SHEET_FIRST_HALF = "TEAM_WIN_CLEAN_SHEET_FIRST_HALF",
  TEAM_WIN_CLEAN_SHEET_SECOND_HALF = "TEAM_WIN_CLEAN_SHEET_SECOND_HALF",
  TEAM_SCORE_FIRST = "TEAM_SCORE_FIRST",
  TEAM_SCORE_FIRST_FIRST_HALF = "TEAM_SCORE_FIRST_FIRST_HALF",
  TEAM_SCORE_FIRST_FIRST_HALF_3_WAY = "TEAM_SCORE_FIRST_FIRST_HALF_3_WAY",
  TEAM_SCORE_FIRST_SECOND_HALF = "TEAM_SCORE_FIRST_SECOND_HALF",
  TEAM_SCORE_FIRST_SECOND_HALF_3_WAY = "TEAM_SCORE_FIRST_SECOND_HALF_3_WAY",
  TEAM_SCORE_FIRST_FIRST_QUARTER = "TEAM_SCORE_FIRST_FIRST_QUARTER",
  TEAM_SCORE_FIRST_FIRST_QUARTER_3_WAY = "TEAM_SCORE_FIRST_FIRST_QUARTER_3_WAY",
  TEAM_SCORE_FIRST_SECOND_QUARTER = "TEAM_SCORE_FIRST_SECOND_QUARTER",
  TEAM_SCORE_FIRST_SECOND_QUARTER_3_WAY = "TEAM_SCORE_FIRST_SECOND_QUARTER_3_WAY",
  TEAM_SCORE_FIRST_THIRD_QUARTER = "TEAM_SCORE_FIRST_THIRD_QUARTER",
  TEAM_SCORE_FIRST_THIRD_QUARTER_3_WAY = "TEAM_SCORE_FIRST_THIRD_QUARTER_3_WAY",
  TEAM_SCORE_FIRST_FOURTH_QUARTER = "TEAM_SCORE_FIRST_FOURTH_QUARTER",
  TEAM_SCORE_FIRST_FOURTH_QUARTER_3_WAY = "TEAM_SCORE_FIRST_FOURTH_QUARTER_3_WAY",
  TEAM_SCORE_FIRST_3_WAY = "TEAM_SCORE_FIRST_3_WAY",
  TEAM_SCORE_LAST = "TEAM_SCORE_LAST",
  TEAM_SCORE_LAST_3_WAY = "TEAM_SCORE_LAST_3_WAY",
  TEAM_SCORE_LAST_FIRST_HALF = "TEAM_SCORE_LAST_FIRST_HALF",
  TEAM_SCORE_LAST_FIRST_HALF_3_WAY = "TEAM_SCORE_LAST_FIRST_HALF_3_WAY",
  TEAM_SCORE_LAST_SECOND_HALF = "TEAM_SCORE_LAST_SECOND_HALF",
  TEAM_SCORE_LAST_SECOND_HALF_3_WAY = "TEAM_SCORE_LAST_SECOND_HALF_3_WAY",
  TEAM_SCORE_LAST_FIRST_QUARTER = "TEAM_SCORE_LAST_FIRST_QUARTER",
  TEAM_SCORE_LAST_FIRST_QUARTER_3_WAY = "TEAM_SCORE_LAST_FIRST_QUARTER_3_WAY",
  TEAM_SCORE_LAST_SECOND_QUARTER = "TEAM_SCORE_LAST_SECOND_QUARTER",
  TEAM_SCORE_LAST_SECOND_QUARTER_3_WAY = "TEAM_SCORE_LAST_SECOND_QUARTER_3_WAY",
  TEAM_SCORE_LAST_THIRD_QUARTER = "TEAM_SCORE_LAST_THIRD_QUARTER",
  TEAM_SCORE_LAST_THIRD_QUARTER_3_WAY = "TEAM_SCORE_LAST_THIRD_QUARTER_3_WAY",
  TEAM_SCORE_LAST_FOURTH_QUARTER = "TEAM_SCORE_LAST_FOURTH_QUARTER",
  TEAM_SCORE_LAST_FOURTH_QUARTER_3_WAY = "TEAM_SCORE_LAST_FOURTH_QUARTER_3_WAY",
  TEAM_MOST_CORNERS_3_WAY = "TEAM_MOST_CORNERS_3_WAY",
  TEAM_MOST_CORNERS_FIRST_HALF_3_WAY = "TEAM_MOST_CORNERS_FIRST_HALF_3_WAY",
  TEAM_MOST_CORNERS_SECOND_HALF_3_WAY = "TEAM_MOST_CORNERS_SECOND_HALF_3_WAY",
  TEAM_MOST_CORNERS_SPREAD_3_WAY = "TEAM_MOST_CORNERS_SPREAD_3_WAY",
  TEAM_MOST_CARDS = "TEAM_MOST_CARDS",
  TEAM_MOST_CARDS_3_WAY = "TEAM_MOST_CARDS_3_WAY",
  TEAM_MOST_CARDS_FIRST_HALF_3_WAY = "TEAM_MOST_CARDS_FIRST_HALF_3_WAY",
  TEAM_MOST_CARDS_SECOND_HALF_3_WAY = "TEAM_MOST_CARDS_SECOND_HALF_3_WAY",
  TEAM_MOST_CARDS_SPREAD = "TEAM_MOST_CARDS_SPREAD",
  TEAM_MOST_CARDS_SPREAD_3_WAY = "TEAM_MOST_CARDS_SPREAD_3_WAY",
  TEAM_MOST_OFFSIDES_3_WAY = "TEAM_MOST_OFFSIDES_3_WAY",
  TEAM_WINNING_AT_H_TOR_FT = "TEAM_WINNING_AT_H_TOR_FT",
  TEAM_SCORE_FROM_PENALTY = "TEAM_SCORE_FROM_PENALTY",
  TEAM_AWARDED_PENALTY = "TEAM_AWARDED_PENALTY",
  TEAM_WIN_ONE_SET = "TEAM_WIN_ONE_SET",
  TEAM_WIN_FIRST_SET_AND_MATCH = "TEAM_WIN_FIRST_SET_AND_MATCH",
  TEAM_LOSE_FIRST_SET_AND_WIN_MATCH = "TEAM_LOSE_FIRST_SET_AND_WIN_MATCH",
  TEAM_MOST_ACES_3_WAY = "TEAM_MOST_ACES_3_WAY",
  TEAM_SCORE_FIRST_ACE = "TEAM_SCORE_FIRST_ACE",
  TEAM_SCORE_FIRST_DOUBLE_FAULT = "TEAM_SCORE_FIRST_DOUBLE_FAULT",
  TEAM_MOST_DOUBLE_FAULTS_3_WAY = "TEAM_MOST_DOUBLE_FAULTS_3_WAY",
  TEAM_SCORE_FIRST_SERVICE_BREAK = "TEAM_SCORE_FIRST_SERVICE_BREAK",
  TEAM_CORNER_FIRST_3_WAY = "TEAM_CORNER_FIRST_3_WAY",
  TEAM_CORNER_FIRST_FIRST_HALF_3_WAY = "TEAM_CORNER_FIRST_FIRST_HALF_3_WAY",
  TEAM_CORNER_FIRST_SECOND_HALF_3_WAY = "TEAM_CORNER_FIRST_SECOND_HALF_3_WAY",
  TEAM_CORNER_LAST_3_WAY = "TEAM_CORNER_LAST_3_WAY",
  TEAM_CORNER_LAST_FIRST_HALF_3_WAY = "TEAM_CORNER_LAST_FIRST_HALF_3_WAY",
  TEAM_CORNER_LAST_SECOND_HALF_3_WAY = "TEAM_CORNER_LAST_SECOND_HALF_3_WAY",
  TEAM_CARD_FIRST_3_WAY = "TEAM_CARD_FIRST_3_WAY",
  TEAM_CARD_LAST_3_WAY = "TEAM_CARD_LAST_3_WAY",
  TEAM_LONGEST_FIELD_GOAL_3_WAY = "TEAM_LONGEST_FIELD_GOAL_3_WAY",
  TEAM_MOST_PUNTS_3_WAY = "TEAM_MOST_PUNTS_3_WAY",
  TEAM_FIRST_SACK_3_WAY = "TEAM_FIRST_SACK_3_WAY",
  TEAM_FIRST_TOUCHDOWN = "TEAM_FIRST_TOUCHDOWN",
  TEAM_FIRST_TOUCHDOWN_3_WAY = "TEAM_FIRST_TOUCHDOWN_3_WAY",
  TEAM_FIRST_FIELD_GOAL = "TEAM_FIRST_FIELD_GOAL",
  TEAM_FIRST_FIELD_GOAL_3_WAY = "TEAM_FIRST_FIELD_GOAL_3_WAY",
  TEAM_FIRST_TURNOVER_3_WAY = "TEAM_FIRST_TURNOVER_3_WAY",
  TEAM_FIRST_PUNT = "TEAM_FIRST_PUNT",
  TEAM_LONGEST_TOUCHDOWN_3_WAY = "TEAM_LONGEST_TOUCHDOWN_3_WAY",
  TEAM_SCORE_EVERY_QUARTER = "TEAM_SCORE_EVERY_QUARTER",
  TEAM_WIN_EVERY_QUARTER = "TEAM_WIN_EVERY_QUARTER",
  TEAM_POINTS_END_ODD = "TEAM_POINTS_END_ODD",
  TEAM_POINTS_END_ODD_FIRST_HALF = "TEAM_POINTS_END_ODD_FIRST_HALF",
  TEAM_POINTS_END_ODD_SECOND_HALF = "TEAM_POINTS_END_ODD_SECOND_HALF",
  TEAM_POINTS_END_ODD_FIRST_QUARTER = "TEAM_POINTS_END_ODD_FIRST_QUARTER",
  TEAM_POINTS_END_ODD_SECOND_QUARTER = "TEAM_POINTS_END_ODD_SECOND_QUARTER",
  TEAM_POINTS_END_ODD_THIRD_QUARTER = "TEAM_POINTS_END_ODD_THIRD_QUARTER",
  TEAM_POINTS_END_ODD_FOURTH_QUARTER = "TEAM_POINTS_END_ODD_FOURTH_QUARTER",
  TEAM_FIRST_POSSESSION_SCORE = "TEAM_FIRST_POSSESSION_SCORE",
  TEAM_FIRST_FREE_THROW_MADE = "TEAM_FIRST_FREE_THROW_MADE",
  TEAM_MOST_THREE_POINTERS_MADE_3_WAY = "TEAM_MOST_THREE_POINTERS_MADE_3_WAY",
  TEAM_WIN_FROM_BEHIND = "TEAM_WIN_FROM_BEHIND",
  GAME_PENALTY_AWARDED = "GAME_PENALTY_AWARDED",
  GAME_GOAL_SCORED_FIRST_HALF = "GAME_GOAL_SCORED_FIRST_HALF",
  GAME_GOAL_SCORED_SECOND_HALF = "GAME_GOAL_SCORED_SECOND_HALF",
  GAME_GOAL_SCORED_BOTH_HALVES = "GAME_GOAL_SCORED_BOTH_HALVES",
  GAME_GOAL_SCORED_BOTH_TEAMS = "GAME_GOAL_SCORED_BOTH_TEAMS",
  GAME_GOAL_SCORED_BOTH_TEAMS_NO_DRAW = "GAME_GOAL_SCORED_BOTH_TEAMS_NO_DRAW",
  GAME_GOAL_SCORED_BOTH_TEAMS_3_WAY = "GAME_GOAL_SCORED_BOTH_TEAMS_3_WAY",
  GAME_GOAL_SCORED_BOTH_TEAMS_FIRST_HALF = "GAME_GOAL_SCORED_BOTH_TEAMS_FIRST_HALF",
  GAME_GOAL_SCORED_BOTH_TEAMS_SECOND_HALF = "GAME_GOAL_SCORED_BOTH_TEAMS_SECOND_HALF",
  GAME_GOAL_SCORED_BOTH_TEAMS_BOTH_HALVES = "GAME_GOAL_SCORED_BOTH_TEAMS_BOTH_HALVES",
  GAME_GOAL_SCORED_BOTH_TEAMS_FIRST_QUARTER = "GAME_GOAL_SCORED_BOTH_TEAMS_FIRST_QUARTER",
  GAME_GOAL_SCORED_BOTH_TEAMS_SECOND_QUARTER = "GAME_GOAL_SCORED_BOTH_TEAMS_SECOND_QUARTER",
  GAME_GOAL_SCORED_BOTH_TEAMS_THIRD_QUARTER = "GAME_GOAL_SCORED_BOTH_TEAMS_THIRD_QUARTER",
  GAME_GOAL_SCORED_BOTH_TEAMS_FOURTH_QUARTER = "GAME_GOAL_SCORED_BOTH_TEAMS_FOURTH_QUARTER",
  GAME_GOAL_SCORED_BOTH_TEAMS_EVERY_QUARTER = "GAME_GOAL_SCORED_BOTH_TEAMS_EVERY_QUARTER",
  GAME_RED_CARD_GIVEN = "GAME_RED_CARD_GIVEN",
  GAME_OWN_GOAL = "GAME_OWN_GOAL",
  GAME_EMPTY_NET_GOAL = "GAME_EMPTY_NET_GOAL",
  GAME_GRAND_SLAM = "GAME_GRAND_SLAM",
  GAME_OVERTIME = "GAME_OVERTIME",
  GAME_WIN_SET_BOTH_PLAYERS = "GAME_WIN_SET_BOTH_PLAYERS",
  GAME_TIE_BREAK = "GAME_TIE_BREAK",
  GAME_TIE_BREAK_FIRST_SET = "GAME_TIE_BREAK_FIRST_SET",
  GAME_TIE_BREAK_SECOND_SET = "GAME_TIE_BREAK_SECOND_SET",
  GAME_TIE_BREAK_THIRD_SET = "GAME_TIE_BREAK_THIRD_SET",
  GAME_TIE_BREAK_FOURTH_SET = "GAME_TIE_BREAK_FOURTH_SET",
  GAME_TIE_BREAK_FIFTH_SET = "GAME_TIE_BREAK_FIFTH_SET",
  GAME_ANY_SET_TO_NIL = "GAME_ANY_SET_TO_NIL",
  GAME_KICKOFF_RETURNED_TD = "GAME_KICKOFF_RETURNED_TD",
  GAME_PUNT_RETURNED_TD = "GAME_PUNT_RETURNED_TD",
  GAME_BOTH_TEAM_SCORE_FIRST_DRIVE = "GAME_BOTH_TEAM_SCORE_FIRST_DRIVE",
  GAME_OPEN_KICKOFF_TOUCHBACK = "GAME_OPEN_KICKOFF_TOUCHBACK",
  GAME_TOUCHDOWN_EVERY_QUARTER = "GAME_TOUCHDOWN_EVERY_QUARTER",
  GAME_FIELD_GOAL_EVERY_QUARTER = "GAME_FIELD_GOAL_EVERY_QUARTER",
  GAME_POINTS_EVERY_QUARTER = "GAME_POINTS_EVERY_QUARTER",
  GAME_TD_AND_FIELD_GOAL_EVERY_QUARTER = "GAME_TD_AND_FIELD_GOAL_EVERY_QUARTER",
  GAME_ANY_QUARTER_SCORELESS = "GAME_ANY_QUARTER_SCORELESS",
  GAME_TWO_POINT_ATTEMPT = "GAME_TWO_POINT_ATTEMPT",
  GAME_TWO_POINT_SUCCESS = "GAME_TWO_POINT_SUCCESS",
  GAME_SAFTEY_SCORED = "GAME_SAFTEY_SCORED",
  GAME_LAST_TEAM_SCORE_WIN_GAME = "GAME_LAST_TEAM_SCORE_WIN_GAME",
  GAME_SPECIAL_OR_DEFENSE_SCORE = "GAME_SPECIAL_OR_DEFENSE_SCORE",
  GAME_ONSIDE_KICK_ATTEMPTED = "GAME_ONSIDE_KICK_ATTEMPTED",
  GAME_BLOCKED_PUNT = "GAME_BLOCKED_PUNT",
  GAME_BLOCKED_FIELD_GOAL = "GAME_BLOCKED_FIELD_GOAL",
  GAME_WINNING_BUZZER_BEATER = "GAME_WINNING_BUZZER_BEATER",
  GAME_POINTS_END_ODD = "GAME_POINTS_END_ODD",
  GAME_POINTS_END_ODD_NO_OT = "GAME_POINTS_END_ODD_NO_OT",
  GAME_POINTS_END_ODD_FIRST_HALF = "GAME_POINTS_END_ODD_FIRST_HALF",
  GAME_POINTS_END_ODD_SECOND_HALF = "GAME_POINTS_END_ODD_SECOND_HALF",
  GAME_POINTS_END_ODD_SECOND_HALF_NO_OT = "GAME_POINTS_END_ODD_SECOND_HALF_NO_OT",
  GAME_POINTS_END_ODD_FIRST_QUARTER = "GAME_POINTS_END_ODD_FIRST_QUARTER",
  GAME_POINTS_END_ODD_SECOND_QUARTER = "GAME_POINTS_END_ODD_SECOND_QUARTER",
  GAME_POINTS_END_ODD_THIRD_QUARTER = "GAME_POINTS_END_ODD_THIRD_QUARTER",
  GAME_POINTS_END_ODD_FOURTH_QUARTER = "GAME_POINTS_END_ODD_FOURTH_QUARTER",
  GAME_POINTS_END_ODD_FOURTH_QUARTER_NO_OT = "GAME_POINTS_END_ODD_FOURTH_QUARTER_NO_OT",
  FIRST_FIELD_GOAL_TEAM = "FIRST_FIELD_GOAL_TEAM",
  FIRST_FIELD_GOAL_TEAM_EXACT_MULTI = "FIRST_FIELD_GOAL_TEAM_EXACT_MULTI",
  FIRST_FIELD_GOAL_TYPE = "FIRST_FIELD_GOAL_TYPE",
}
