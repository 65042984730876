import React from "react";
import { Box, Stack } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { FootballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/FootballLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import { determineYardsToGo, formatPeriod, isInRedZone } from "../Utils";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";

type DefaultFootballDesignProps = {
    gameLiveData: FootballLiveDataTypeFragment;
    homeTeamAbbr?: string;
    awayTeamAbbr?: string;
    showAdditionalData?: boolean;
};

function DefaultFootballDesign({ gameLiveData, homeTeamAbbr, awayTeamAbbr, showAdditionalData }: DefaultFootballDesignProps) {
    const {
        awayTeamTotalPoints = [0],
        homeTeamTotalPoints = [0],
        homeTeamHasPossession = false,
        period = "00",
        gameTimeSeconds = 0,
        activeDown = "00",
        yardsToNextDown = "00",
        yardsDistance = "00",
        additionalData = "",
    } = gameLiveData;

    const homeTeamScore = homeTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const isHomeTeamPossession = homeTeamHasPossession;
    const formattedYardsToGo = determineYardsToGo((yardsDistance?.toString() || ""), homeTeamAbbr, awayTeamAbbr, isHomeTeamPossession);
    const redZoneStatus = isInRedZone(yardsDistance?.toString() || "");

    return (
        <Box>
            <Stack direction="row" spacing={1} alignItems="start">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Stack direction="column" spacing={0.5} alignItems="end" sx={{ flexGrow: 1 }}>
                        <ScoreIndicatorLiveData score={awayTeamScore?.toString()} isServing={!isHomeTeamPossession} design={LiveDataDesign.COMPACT} isInRedZone={!isHomeTeamPossession && redZoneStatus} />
                        <ScoreIndicatorLiveData score={homeTeamScore?.toString()} isServing={isHomeTeamPossession} design={LiveDataDesign.COMPACT} isInRedZone={isHomeTeamPossession && redZoneStatus} />
                    </Stack>

                    <Stack direction="column" gap={0.5} >
                        <Stack direction="row" spacing={1} alignItems="center">
                            <StyledTypography variant="caption" color="text.primary">
                                {formatPeriod(period)} {formatSeconds(gameTimeSeconds)}
                            </StyledTypography>
                        </Stack>
                        <StyledTypography variant="caption" color="text.primary" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                            {activeDown} & {yardsToNextDown} on {formattedYardsToGo}
                        </StyledTypography>
                    </Stack>
                </Stack>
                {
                    showAdditionalData && (
                        <Box>
                            <StyledTypography
                                lineClamp={3}
                                color="text.secondary"
                                variant="caption"
                                sx={{
                                    lineHeight: "1em",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    whiteSpace: "normal",
                                }}
                            >
                                {additionalData}
                            </StyledTypography>
                        </Box>
                    )}
            </Stack>
        </Box>
    );
};

export default DefaultFootballDesign;
