import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { fromUnixTime, formatDistanceToNowStrict } from "date-fns";
import { SoccerLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/SoccerLiveDataTypeFragment";
import { LiveDataDesign } from "../DesignEnum";
import DefaultSoccerDesign from "./DefaultSoccerDesign";
import CompactSoccerDesign from "./CompactSoccerDesign";
import DetailedSoccerDesign from "./DetailedSoccerDesign";

type SoccerLiveDataProps = {
    gameLiveData: SoccerLiveDataTypeFragment | null;
    startTime?: number;
    design: LiveDataDesign;
    showAdditionalData?: boolean;
};

function SoccerLiveData({ gameLiveData, startTime, design, showAdditionalData }: SoccerLiveDataProps) {
    const startDate = useMemo(() => (startTime ? fromUnixTime(startTime) : null), [startTime]);
    const elapsedTime = useMemo(() => (startDate ? formatDistanceToNowStrict(startDate, { addSuffix: false }) : ""), [startDate]);
    const currentTime = new Date();
    const isFuture = startDate && startDate > currentTime;

    if (!gameLiveData) {
        return (
            <Box sx={{ textAlign: "center", p: 2 }}>
                <p>Game data is unavailable.</p>
            </Box>
        );
    }

    const designMap: Record<LiveDataDesign, React.ElementType> = {
        [LiveDataDesign.DEFAULT]: DefaultSoccerDesign,
        [LiveDataDesign.COMPACT]: CompactSoccerDesign,
        [LiveDataDesign.DETAILED]: DetailedSoccerDesign,
    };

    const DesignComponent = designMap[design] || DefaultSoccerDesign;

    return <DesignComponent gameLiveData={gameLiveData} elapsedTime={elapsedTime} isFuture={isFuture} showAdditionalData={showAdditionalData} />;
}

export default SoccerLiveData;
