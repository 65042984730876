// footballUtils.ts
export const determineYardsToGo = (
    yardsToGo: string,
    homeTeamAbbr?: string,
    awayTeamAbbr?: string,
    homeTeamHasPossession?: boolean,
): string => {
    const yards = parseInt(yardsToGo, 10);

    if (Number.isNaN(yards) || !homeTeamAbbr || !awayTeamAbbr) return "00";

    const teamWithPossession = homeTeamHasPossession ? homeTeamAbbr : awayTeamAbbr;

    if (yards >= 50) {
        const displayYards = 50 - (yards - 50);
        return `${teamWithPossession} ${displayYards}`;
    }

    const opponentTeam = homeTeamHasPossession ? awayTeamAbbr : homeTeamAbbr;
    return `${opponentTeam} ${yards}`;
};

export const formatPeriod = (period: string): string => {
    if (period === "Halftime") return "Half";

    const periodNumber = parseInt(period.replace("Q", ""), 10);
    if (Number.isNaN(periodNumber)) return "00";

    let suffix = "th";

    if (periodNumber % 10 === 1 && periodNumber % 100 !== 11) {
        suffix = "st";
    } else if (periodNumber % 10 === 2 && periodNumber % 100 !== 12) {
        suffix = "nd";
    } else if (periodNumber % 10 === 3 && periodNumber % 100 !== 13) {
        suffix = "rd";
    }

    return `${periodNumber}${suffix}`;
};

export const isInRedZone = (yardsDistance: string): boolean => {
    const yardsDistanceNumber = parseInt(yardsDistance, 10);
    if (Number.isNaN(yardsDistanceNumber)) return false;
    return yardsDistanceNumber <= 20
};


// Function to customize the formatting of elapsed time
export const formatCustomElapsedTime = (elapsedTime: string) =>
    elapsedTime
        .replace("minute", "min") // Replace "minute" with "min"
        .replace("minutes", "min") // Replace "minutes" with "min"
        .replace("second", "sec") // Replace "second" with "sec" if needed
        .replace("seconds", "sec"); // Replace "seconds" with "sec" if needed
