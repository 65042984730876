import React, { useState } from "react";
import { Box, Stack, IconButton } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { BaseballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BaseballLiveDataTypeFragment";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import { formatPeriod } from "../Utils";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";
import { RenderBases, RenderInningIndicator, RenderOuts } from "./DefaultBaseballDesign";

type DefaultBaseballDesignProps = {
    gameLiveData: BaseballLiveDataTypeFragment;
};

const views = ["default", "score", "homeRun", "grandSlam", "outs"] as const;
type ViewType = typeof views[number];

type RenderScoreByInningProps = {
    dataType: ViewType;
    homeTeamData: number[];
    awayTeamData: number[];
};

function RenderScoreByInning({ dataType, homeTeamData, awayTeamData }: RenderScoreByInningProps) {
    const scoreCellWidth = 13;
    const t = useTranslations("liveData");
    const headers = {
        score: t("score_by_inning"),
        homeRun: t("homeRun_by_inning"),
        grandSlam: t("grandSlam_by_inning"),
        outs: t("outs_by_inning"),
    };
    const headerText = dataType !== "default" ? headers[dataType] : "";

    return (
        <Stack direction="column" spacing={0} alignItems="flex-start" sx={{ width: "100%" }}>
            <StyledTypography variant="caption" color="text.secondary" sx={{ mb: 0 }}>
                {headerText}
            </StyledTypography>
            <Stack direction="row" spacing={2} alignItems="flex-start">
                {Array.from({ length: 9 }, (_, index) => (
                    <StyledTypography key={`header-${index}`} variant="caption" color="text.secondary" sx={{ width: `${scoreCellWidth}px`, textAlign: "center" }}>
                        {index + 1}
                    </StyledTypography>
                ))}
                <StyledTypography variant="caption" color="text.secondary">
                    {dataType === "outs" ? "O" : "R"}
                </StyledTypography>
            </Stack>
            <Stack direction="row" spacing={2}>
                {Array(9).fill(undefined).map((_, index) => (
                    <StyledTypography key={`home-${dataType}-${index}`} variant="caption" color="text.primary" sx={{ width: `${scoreCellWidth}px`, textAlign: "center" }}>
                        {homeTeamData[index] !== undefined && homeTeamData[index] !== null ? homeTeamData[index] : "-"}
                    </StyledTypography>
                ))}
                <StyledTypography variant="caption" color="text.primary">
                    {homeTeamData.reduce((acc, curr) => acc + (curr ?? 0), 0)}
                </StyledTypography>
            </Stack>
            <Stack direction="row" spacing={2}>
                {Array(9).fill(undefined).map((_, index) => (
                    <StyledTypography key={`away-${dataType}-${index}`} variant="caption" color="text.primary" sx={{ width: `${scoreCellWidth}px`, textAlign: "center" }}>
                        {awayTeamData[index] !== undefined && awayTeamData[index] !== null ? awayTeamData[index] : "-"}
                    </StyledTypography>
                ))}
                <StyledTypography variant="caption" color="text.primary">
                    {awayTeamData.reduce((acc, curr) => acc + (curr ?? 0), 0)}
                </StyledTypography>
            </Stack>
        </Stack>
    );
};

function DetailedBaseballDesign({ gameLiveData }: DefaultBaseballDesignProps) {
    const [currentView, setCurrentView] = useState<ViewType>("default");
    const {
        awayTeamScoreByInning = [0],
        homeTeamScoreByInning = [0],
        isHomeTeamBatting = false,
        period = "00",
        currentStrikes = 0,
        currentBalls = 0,
        awayTeamCurrentBases = [false, false, false],
        homeTeamCurrentBases = [false, false, false],
        awayTeamOutsByInning = [0],
        homeTeamOutsByInning = [0],
        awayTeamHomeRunByInning = [0],
        homeTeamHomeRunByInning = [0],
        awayTeamGrandSlamsByInning = [0],
        homeTeamGrandSlamsByInning = [0],
        additionalData,
    } = gameLiveData;

    const homeTeamScore = homeTeamScoreByInning?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByInning?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const currentOuts = (isHomeTeamBatting ? awayTeamOutsByInning : homeTeamOutsByInning) || [];

    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next" ? (currentIndex + 1) % views.length : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={0.5}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <StyledTypography variant="caption" color="text.primary">
                        {formatPeriod(period)}
                    </StyledTypography>
                </Stack>
                <Stack alignItems="center" justifyContent="center" direction="row" gap={0.5} sx={{ flexGrow: 1 }}>
                    <StyledTypography variant="body2">Live Data</StyledTypography>
                    <SensorsIcon fontSize="small" color="error" />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon fontSize="small" />
                    </IconButton>
                </Stack>
            </Stack>
            {currentView === "default" && (
                <Stack direction="row" spacing={1} alignItems="start" mb={2.5} mt={2.5}>
                    <Stack direction="row" spacing={1} alignItems="center" width={135}>
                        <Stack direction="column" spacing={0} alignItems="end">
                            <ScoreIndicatorLiveData score={homeTeamScore?.toString()} isServing={false} design={LiveDataDesign.COMPACT} />
                            <ScoreIndicatorLiveData score={awayTeamScore?.toString()} isServing={false} design={LiveDataDesign.COMPACT} />
                        </Stack>
                        <Stack direction="column" alignItems="center">
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} pt={2.5}>
                                {RenderBases((isHomeTeamBatting ? homeTeamCurrentBases : awayTeamCurrentBases) || [false, false, false])}
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>{RenderOuts(currentOuts)}</Box>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                            <StyledTypography mt={0.5} variant="body2" color="text.primary">
                                {currentBalls}
                            </StyledTypography>
                            <StyledTypography mt={0.5} variant="body2" color="text.secondary">
                                -
                            </StyledTypography>
                            <StyledTypography mt={0.5} variant="body2" color="text.primary">
                                {currentStrikes}
                            </StyledTypography>
                        </Stack>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <StyledTypography variant="body2" color="text.secondary">
                                {RenderInningIndicator(parseInt(period, 10), isHomeTeamBatting)}
                            </StyledTypography>
                        </Box>
                    </Stack>
                    <Box sx={{ marginTop: "auto !important" }} width={135}>
                        <StyledTypography
                            lineClamp={4}
                            color="text.secondary"
                            variant="caption"
                            sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                lineHeight: "1em",
                                whiteSpace: "normal",
                            }}
                        >
                            {additionalData}
                        </StyledTypography>
                    </Box>
                </Stack>
            )}
            {currentView !== "default" && (
                <RenderScoreByInning
                    dataType={currentView}
                    homeTeamData={(() => {
                        switch (currentView) {
                            case "score":
                                return homeTeamScoreByInning ?? [];
                            case "homeRun":
                                return homeTeamHomeRunByInning ?? [];
                            case "grandSlam":
                                return homeTeamGrandSlamsByInning ?? [];
                            case "outs":
                                return homeTeamOutsByInning ?? [];
                            default:
                                return [];
                        }
                    })()}
                    awayTeamData={(() => {
                        switch (currentView) {
                            case "score":
                                return awayTeamScoreByInning ?? [];
                            case "homeRun":
                                return awayTeamHomeRunByInning ?? [];
                            case "grandSlam":
                                return awayTeamGrandSlamsByInning ?? [];
                            case "outs":
                                return awayTeamOutsByInning ?? [];
                            default:
                                return [];
                        }
                    })()}
                />
            )}
        </Box>
    );
}

export default DetailedBaseballDesign;