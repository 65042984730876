import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { BasketballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BasketballLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { formatPeriod } from "../Utils";
import { LiveDataDesign } from "../DesignEnum";

type DefaultBasketballDesignProps = {
    gameLiveData: BasketballLiveDataTypeFragment;
};

const views = ["default", "fouls", "threePointers", "twoPointers", "freeThrows", "freeThrowsMissed", "freeThrowsPending"] as const;
type ViewType = typeof views[number];

function DetailedBasketballDesign({ gameLiveData }: DefaultBasketballDesignProps) {
    const theme = useTheme();
    const [currentView, setCurrentView] = useState<ViewType>("default");
    const t = useTranslations("liveData");
    const {
        homeTeamTotalPointsByQuarter = [0],
        awayTeamTotalPointsByQuarter = [0],
        period = "",
        gameTimeSeconds = 0,
        homeTeamHasPossession = false,
        additionalData = "",
        homeTeamFoulsByQuarter = [0],
        awayTeamFoulsByQuarter = [0],
        homeTeamThreePointersByQuarter = [0],
        awayTeamThreePointersByQuarter = [0],
        homeTeamTwoPointersByQuarter = [0],
        awayTeamTwoPointersByQuarter = [0],
        homeTeamFreeThrowsScoredByQuarter = [0],
        awayTeamFreeThrowsScoredByQuarter = [0],
        homeTeamFreeThrowsMissedByQuarter = [0],
        awayTeamFreeThrowsMissedByQuarter = [0],
        homeTeamFreeThrowsPendingByQuarter = [0],
        awayTeamFreeThrowsPendingByQuarter = [0],
    } = gameLiveData;

    const homeTeamScore = homeTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const awayTeamScore = awayTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const formattedPeriod = formatPeriod(period);
    const formattedGameTime = formatSeconds(gameTimeSeconds);

    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next" ? (currentIndex + 1) % views.length : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    const getViewTitle = () => {
        switch (currentView) {
            case "default":
                return t("scores_by_quarter");
            case "fouls":
                return t("fouls");
            case "threePointers":
                return t("three_pointers");
            case "twoPointers":
                return t("two_pointers");
            case "freeThrows":
                return t("free_throws");
            case "freeThrowsMissed":
                return t("free_throws_missed");
            case "freeThrowsPending":
                return t("free_throws_pending");
            default:
                return "";
        }
    };

    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    const getViewData = () => {
        switch (currentView) {
            case "default":
                return {
                    awayData: awayTeamTotalPointsByQuarter,
                    homeData: homeTeamTotalPointsByQuarter,
                    awayScore: awayTeamScore,
                    homeScore: homeTeamScore,
                };
            case "fouls":
                return {
                    awayData: awayTeamFoulsByQuarter,
                    homeData: homeTeamFoulsByQuarter,
                    awayScore: getTotal(awayTeamFoulsByQuarter),
                    homeScore: getTotal(homeTeamFoulsByQuarter),
                };
            case "threePointers":
                return {
                    awayData: awayTeamThreePointersByQuarter,
                    homeData: homeTeamThreePointersByQuarter,
                    awayScore: getTotal(awayTeamThreePointersByQuarter),
                    homeScore: getTotal(homeTeamThreePointersByQuarter),
                };
            case "twoPointers":
                return {
                    awayData: awayTeamTwoPointersByQuarter,
                    homeData: homeTeamTwoPointersByQuarter,
                    awayScore: getTotal(awayTeamTwoPointersByQuarter),
                    homeScore: getTotal(homeTeamTwoPointersByQuarter),
                };
            case "freeThrows":
                return {
                    awayData: awayTeamFreeThrowsScoredByQuarter,
                    homeData: homeTeamFreeThrowsScoredByQuarter,
                    awayScore: getTotal(awayTeamFreeThrowsScoredByQuarter),
                    homeScore: getTotal(homeTeamFreeThrowsScoredByQuarter),
                };
            case "freeThrowsMissed":
                return {
                    awayData: awayTeamFreeThrowsMissedByQuarter,
                    homeData: homeTeamFreeThrowsMissedByQuarter,
                    awayScore: getTotal(awayTeamFreeThrowsMissedByQuarter),
                    homeScore: getTotal(homeTeamFreeThrowsMissedByQuarter),
                };
            case "freeThrowsPending":
                return {
                    awayData: awayTeamFreeThrowsPendingByQuarter,
                    homeData: homeTeamFreeThrowsPendingByQuarter,
                    awayScore: getTotal(awayTeamFreeThrowsPendingByQuarter),
                    homeScore: getTotal(homeTeamFreeThrowsPendingByQuarter),
                };
            default:
                return {
                    awayData: [],
                    homeData: [],
                    awayScore: 0,
                    homeScore: 0,
                };
        }
    };

    const { awayData, homeData, awayScore, homeScore } = getViewData();

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                    <StyledTypography variant="caption" color="text.primary">
                        {formattedPeriod} {formattedGameTime}
                    </StyledTypography>
                </Stack>
                <Stack alignItems="center" justifyContent="center" direction="row" gap={0.5} sx={{ flexGrow: 1 }}>
                    <StyledTypography variant="body2">
                        Live Data
                    </StyledTypography>
                    <SensorsIcon fontSize="small" color="error" />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1} alignItems="start" mt={0.5}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack width={150}>
                        <StyledTypography
                            color="text.secondary"
                            variant="caption"
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    </Stack>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="start">
                    <Stack direction="row" spacing={1} alignItems="center" width={145}>
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                borderRight: `0.5px solid ${theme.palette.border.primary}`,
                                pr: 1,
                                flexShrink: 0,
                            }}
                        >
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {["1", "2", "3", "4"].map((header, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="body2"
                                        key={`header-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {header}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(4).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        color="text.primary"
                                        variant="body2"
                                        key={`away-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {awayData && awayData[index] !== undefined ? awayData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(4).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        variant="body2"
                                        key={`home-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {homeData && homeData[index] !== undefined ? homeData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing={0} mt={1} alignItems="start" sx={{ flexGrow: 1 }}>
                            <StyledTypography
                                color="text.secondary"
                                variant="body2"
                                sx={{ width: 16, textAlign: 'center', ml: -0.5 }}
                            >
                                T
                            </StyledTypography>
                            <ScoreIndicatorLiveData score={awayScore?.toString()} isServing={!homeTeamHasPossession} design={LiveDataDesign.DETAILED} view={currentView} />
                            <ScoreIndicatorLiveData score={homeScore?.toString()} isServing={homeTeamHasPossession} design={LiveDataDesign.DETAILED} view={currentView} />
                        </Stack>
                    </Stack>
                    <Box sx={{ marginTop: "auto !important" }} width={135}>
                        <StyledTypography
                            lineClamp={4}
                            color="text.secondary"
                            variant="caption"
                            sx={{
                                lineHeight: "1em",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                whiteSpace: "normal",
                            }}
                        >
                            {additionalData}
                        </StyledTypography>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
}

export default DetailedBasketballDesign;