import { BET_MARKET_TYPE_GROUP_ENUM } from "../Common/Enums/BetMarketTypeGroupEnum";

export const TABS_NAME = [
  {
    enum: BET_MARKET_TYPE_GROUP_ENUM.MONEYLINE,
    displayName: "Moneyline",
  },
  {
    enum: BET_MARKET_TYPE_GROUP_ENUM.SPREAD,
    displayName: "Spread",
  },
  {
    enum: BET_MARKET_TYPE_GROUP_ENUM.TOTALS,
    displayName: "Total",
  },
  {
    enum: BET_MARKET_TYPE_GROUP_ENUM.PLAYER_PROP,
    displayName: "Player Prop",
  },
  {
    enum: BET_MARKET_TYPE_GROUP_ENUM.TEAM,
    displayName: "Team",
  },
  {
    enum: BET_MARKET_TYPE_GROUP_ENUM.RACE_TO,
    displayName: "Race To",
  },
  {
    enum: BET_MARKET_TYPE_GROUP_ENUM.GAME,
    displayName: "Game",
  },
];
