import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import SensorsIcon from "@mui/icons-material/Sensors";
import { TennisLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";
import { formatPeriod } from "../Utils";

type DefaultTennisDesignProps = {
    gameLiveData: TennisLiveDataTypeFragment;
    elapsedTime: string;
    showAdditionalData?: boolean;
};

const views = ["default", "aces", "breaks"] as const;
type ViewType = typeof views[number];

function DetailedTennisDesign({ gameLiveData, elapsedTime, showAdditionalData }: DefaultTennisDesignProps) {
    const theme = useTheme();
    const t = useTranslations("liveData");
    const [currentView, setCurrentView] = useState<ViewType>("default");
    const {
        player1GamesBySet = [],
        player2GamesBySet = [],
        isPlayer1Serving = false,
        player1Points = 0,
        player2Points = 0,
        additionalData,
        player1AcesBySet = [],
        player2AcesBySet = [],
        player1BreaksBySet = [],
        player2BreaksBySet = [],
        surfaceType,
        period
    } = gameLiveData;

    const player1Score = player1Points?.toString();
    const player2Score = player2Points?.toString();

    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next" ? (currentIndex + 1) % views.length : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    const getViewTitle = () => {
        switch (currentView) {
            case "default":
                return t("scores_by_set");
            case "aces":
                return t("aces_by_set");
            case "breaks":
                return t("breaks_by_set");
            default:
                return "";
        }
    };

    const getViewData = () => {
        switch (currentView) {
            case "default":
                return {
                    player1Data: player1GamesBySet,
                    player2Data: player2GamesBySet,
                    player1Score,
                    player2Score,
                };
            case "aces":
                return {
                    player1Data: player1AcesBySet,
                    player2Data: player2AcesBySet,
                    player1Score: getTotal(player1AcesBySet)?.toString(),
                    player2Score: getTotal(player2AcesBySet)?.toString(),
                };
            case "breaks":
                return {
                    player1Data: player1BreaksBySet,
                    player2Data: player2BreaksBySet,
                    player1Score: getTotal(player1BreaksBySet)?.toString(),
                    player2Score: getTotal(player2BreaksBySet)?.toString(),
                };
            default:
                return {
                    player1Data: [],
                    player2Data: [],
                    player1Score: "0",
                    player2Score: "0",
                };
        }
    };

    const { player1Data, player2Data, player1Score: viewPlayer1Score, player2Score: viewPlayer2Score } = getViewData();

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                    <StyledTypography variant="caption" color="text.primary">
                        {formatPeriod(period)} {elapsedTime}
                    </StyledTypography>
                </Stack>
                <Stack alignItems="center" justifyContent="center" direction="row" gap={0.5} sx={{ flexGrow: 1 }}>
                    <StyledTypography variant="body2">
                        Live Data
                    </StyledTypography>
                    <SensorsIcon fontSize="small" color="error" />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1} alignItems="start" mt={0.5}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack width={150}>
                        <StyledTypography
                            color="text.secondary"
                            variant="caption"
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    </Stack>
                    <Stack direction="row">
                        <StyledTypography variant="caption" color="text.secondary" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                            Surface: {surfaceType}
                        </StyledTypography>
                    </Stack>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="start">
                    <Stack direction="row" spacing={1} alignItems="center" width={145}>
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                borderRight: `0.5px solid ${theme.palette.border.primary}`,
                                pr: 1,
                                flexShrink: 0,
                            }}
                        >
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {["1", "2", "3"].map((header, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="body2"
                                        key={`header-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {header}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(3).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        variant="body2"
                                        key={`player1-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {player1Data && player1Data[index] !== undefined ? player1Data[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(3).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        variant="body2"
                                        key={`player2-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {player2Data && player2Data[index] !== undefined ? player2Data[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing={0} mt={1} alignItems="start" sx={{ flexGrow: 1 }}>
                            <StyledTypography
                                color="text.secondary"
                                variant="body2"
                                sx={{ width: 16, textAlign: 'center', ml: -0.5 }}
                            >
                                T
                            </StyledTypography>
                            <ScoreIndicatorLiveData score={viewPlayer1Score} isServing={isPlayer1Serving} design={LiveDataDesign.DETAILED} view={currentView} />
                            <ScoreIndicatorLiveData score={viewPlayer2Score} isServing={!isPlayer1Serving} design={LiveDataDesign.DETAILED} view={currentView} />
                        </Stack>
                    </Stack>
                    {
                        showAdditionalData && (
                            <Box sx={{ marginTop: "auto !important" }} width={135}>
                                <StyledTypography
                                    lineClamp={4}
                                    color="text.secondary"
                                    variant="caption"
                                    sx={{
                                        lineHeight: "1em",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {additionalData}
                                </StyledTypography>
                            </Box>
                        )}
                </Stack>
            </Stack>
        </Box>
    );
}

export default DetailedTennisDesign;
