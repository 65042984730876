import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { fromUnixTime, formatDistanceToNowStrict } from "date-fns";
import { IceHockeyLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/IceHockeyLiveDataTypeFragment";
import { LiveDataDesign } from "../DesignEnum";
import DefaultIceHockeyDesign from "./DefaultIceHockeyDesign";
import CompactIceHockeyDesign from "./CompactIceHockeyDesign";
import DetailedIceHockeyDesign from "./DetailedIceHockeyDesign";

type IceHockeyLiveDataProps = {
    gameLiveData: IceHockeyLiveDataTypeFragment | null;
    startTime?: number;
    design: LiveDataDesign;
    showAdditionalData?: boolean;
};


function IceHockeyLiveData({ gameLiveData, startTime, design, showAdditionalData }: IceHockeyLiveDataProps) {
    const startDate = useMemo(() => (startTime ? fromUnixTime(startTime) : null), [startTime]);
    const elapsedTime = useMemo(() => (startDate ? formatDistanceToNowStrict(startDate, { addSuffix: false }) : ""), [startDate]);
    const currentTime = new Date();
    const isFuture = startDate && startDate > currentTime;

    if (!gameLiveData) {
        return (
            <Box sx={{ textAlign: "center", p: 2 }}>
                <p>Game data is unavailable.</p>
            </Box>
        );
    }

    const designMap: Record<LiveDataDesign, React.ElementType> = {
        [LiveDataDesign.DEFAULT]: DefaultIceHockeyDesign,
        [LiveDataDesign.COMPACT]: CompactIceHockeyDesign,
        [LiveDataDesign.DETAILED]: DetailedIceHockeyDesign,
    };

    const DesignComponent = designMap[design] || DefaultIceHockeyDesign;

    return <DesignComponent gameLiveData={gameLiveData} elapsedTime={elapsedTime} isFuture={isFuture} showAdditionalData={showAdditionalData} />;
}

export default IceHockeyLiveData;
