import { BetMarketTypeEnum } from "@/components/Common/Enums/BetMarketTypeEnum";
import { League } from "@/components/Common/Enums/LeagueEnum";
import { Sports } from "@/components/Common/Enums/SportsEnum";
import { BaseballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BaseballLiveDataTypeFragment";
import { BasketballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BasketballLiveDataTypeFragment";
import { FootballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/FootballLiveDataTypeFragment";
import { IceHockeyLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/IceHockeyLiveDataTypeFragment";
import { SoccerLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/SoccerLiveDataTypeFragment";
import { TennisLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import { GameLiveDataType } from "@/store/gameLiveData";
import { useAppSelector } from "@/store/store";
import getSportsOfLeague from "@/utis/getSportsOfLeague";

function sumArray(numbers: number[] | null | undefined) {
  return numbers ? numbers.reduce((a, b) => a + b, 0) : 0;
}

function formatValue(number: number) {
  if (number > 0) return `+ ${number}`;
  return number;
}

function getCurrentlyFromGameObject(
  game: GameLiveDataType,
  league: League,
  betMarketType: BetMarketTypeEnum,
  isAway?: boolean,
  playerId?: number,
) {
  const sports = getSportsOfLeague(league);
  switch (sports) {
    case Sports.FOOTBALL: {
      // eslint-disable-next-line no-case-declarations
      const gameType = game as FootballLiveDataTypeFragment;
      const player = gameType.players?.find((p) => p.playerId === playerId);
      switch (betMarketType) {
        // Total Game Points
        case BetMarketTypeEnum.TOTAL_GAME_POINTS:
          return sumArray(gameType.awayTeamTotalPoints) + sumArray(gameType.homeTeamTotalPoints);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FIRST_QUARTER:
          return (gameType.awayTeamTotalPoints?.[0] || 0) + (gameType.homeTeamTotalPoints?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SECOND_QUARTER:
          return (gameType.awayTeamTotalPoints?.[1] || 0) + (gameType.homeTeamTotalPoints?.[1] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_THIRD_QUARTER:
          return (gameType.awayTeamTotalPoints?.[2] || 0) + (gameType.homeTeamTotalPoints?.[2] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FOURTH_QUARTER:
          return (gameType.awayTeamTotalPoints?.[3] || 0) + (gameType.homeTeamTotalPoints?.[3] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FIRST_HALF:
          return sumArray(gameType.awayTeamTotalPoints?.slice(0, 2)) + sumArray(gameType.homeTeamTotalPoints?.slice(0, 2));
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SECOND_HALF:
          return sumArray(gameType.awayTeamTotalPoints?.slice(2)) + sumArray(gameType.homeTeamTotalPoints?.slice(2));
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM:
          return isAway ? sumArray(gameType.awayTeamTotalPoints) : sumArray(gameType.homeTeamTotalPoints);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FIRST_QUARTER:
          return isAway ? gameType.awayTeamTotalPoints?.[0] || 0 : gameType.homeTeamTotalPoints?.[0] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SECOND_QUARTER:
          return isAway ? gameType.awayTeamTotalPoints?.[1] || 0 : gameType.homeTeamTotalPoints?.[1] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_THIRD_QUARTER:
          return isAway ? gameType.awayTeamTotalPoints?.[2] || 0 : gameType.homeTeamTotalPoints?.[2] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FOURTH_QUARTER:
          return isAway ? gameType.awayTeamTotalPoints?.[3] || 0 : gameType.homeTeamTotalPoints?.[3] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FIRST_HALF:
          return isAway
            ? sumArray(gameType.awayTeamTotalPoints?.slice(0, 2))
            : sumArray(gameType.homeTeamTotalPoints?.slice(0, 2));
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SECOND_HALF:
          return isAway ? sumArray(gameType.awayTeamTotalPoints?.slice(2)) : sumArray(gameType.homeTeamTotalPoints?.slice(2));

        // Total Game TouchDowns
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS:
          return sumArray(gameType.awayTeamTouchdowns) + sumArray(gameType.homeTeamTouchdowns);
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_FIRST_QUARTER:
          return (gameType.awayTeamTouchdowns?.[0] || 0) + (gameType.homeTeamTouchdowns?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_SECOND_QUARTER:
          return (gameType.awayTeamTouchdowns?.[1] || 0) + (gameType.homeTeamTouchdowns?.[1] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_THIRD_QUARTER:
          return (gameType.awayTeamTouchdowns?.[2] || 0) + (gameType.homeTeamTouchdowns?.[2] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_FOURTH_QUARTER:
          return (gameType.awayTeamTouchdowns?.[3] || 0) + (gameType.homeTeamTouchdowns?.[3] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_FIRST_HALF:
          return sumArray(gameType.awayTeamTouchdowns?.slice(0, 2)) + sumArray(gameType.homeTeamTouchdowns?.slice(0, 2));
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_SECOND_HALF:
          return sumArray(gameType.awayTeamTouchdowns?.slice(2)) + sumArray(gameType.homeTeamTouchdowns?.slice(2));
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_TEAM:
          return isAway ? sumArray(gameType.awayTeamTouchdowns) : sumArray(gameType.homeTeamTouchdowns);
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_TEAM_FIRST_QUARTER:
          return isAway ? gameType.awayTeamTouchdowns?.[0] || 0 : gameType.homeTeamTouchdowns?.[0] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_TEAM_SECOND_QUARTER:
          return isAway ? gameType.awayTeamTouchdowns?.[1] || 0 : gameType.homeTeamTouchdowns?.[1] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_TEAM_THIRD_QUARTER:
          return isAway ? gameType.awayTeamTouchdowns?.[2] || 0 : gameType.homeTeamTouchdowns?.[2] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_TEAM_FOURTH_QUARTER:
          return isAway ? gameType.awayTeamTouchdowns?.[3] || 0 : gameType.homeTeamTouchdowns?.[3] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_TEAM_FIRST_HALF:
          return isAway ? sumArray(gameType.awayTeamTouchdowns?.slice(0, 2)) : sumArray(gameType.homeTeamTouchdowns?.slice(0, 2));
        case BetMarketTypeEnum.TOTAL_GAME_TOUCHDOWNS_TEAM_SECOND_HALF:
          return isAway ? sumArray(gameType.awayTeamTouchdowns?.slice(2)) : sumArray(gameType.homeTeamTouchdowns?.slice(2));

        // Spreads
        case BetMarketTypeEnum.SPREAD:
          return formatValue(sumArray(gameType.awayTeamTotalPoints) - sumArray(gameType.homeTeamTotalPoints));
        case BetMarketTypeEnum.SPREAD_FIRST_QUARTER:
          return formatValue((gameType.awayTeamTotalPoints?.[0] || 0) - (gameType.homeTeamTotalPoints?.[0] || 0));
        case BetMarketTypeEnum.SPREAD_SECOND_QUARTER:
          return formatValue((gameType.awayTeamTotalPoints?.[1] || 0) - (gameType.homeTeamTotalPoints?.[1] || 0));
        case BetMarketTypeEnum.SPREAD_THIRD_QUARTER:
          return formatValue((gameType.awayTeamTotalPoints?.[2] || 0) - (gameType.homeTeamTotalPoints?.[2] || 0));
        case BetMarketTypeEnum.SPREAD_FOURTH_QUARTER:
          return formatValue((gameType.awayTeamTotalPoints?.[3] || 0) - (gameType.homeTeamTotalPoints?.[3] || 0));
        case BetMarketTypeEnum.SPREAD_FIRST_HALF:
          return formatValue(
            sumArray(gameType.awayTeamTotalPoints?.slice(0, 2)) - sumArray(gameType.homeTeamTotalPoints?.slice(0, 2)),
          );
        case BetMarketTypeEnum.SPREAD_SECOND_HALF:
          return formatValue(sumArray(gameType.awayTeamTotalPoints?.slice(2)) - sumArray(gameType.homeTeamTotalPoints?.slice(2)));

        // Player Props
        case BetMarketTypeEnum.PLAYER_PROP_PASSING_YARDS:
          return player?.passing?.yards;
        case BetMarketTypeEnum.PLAYER_PROP_PASSING_COMPLETIONS:
          return player?.passing?.completions;
        case BetMarketTypeEnum.PLAYER_PROP_LONGEST_COMPLETION:
          return player?.passing?.longestYards;
        case BetMarketTypeEnum.PLAYER_PROP_PASSING_ATTEMPTS:
          return player?.passing?.attempts;
        case BetMarketTypeEnum.PLAYER_PROP_PASSING_TOUCHDOWNS:
          return player?.passing?.touchdowns;
        case BetMarketTypeEnum.PLAYER_PROP_THROWN_INTERCEPTIONS:
          return player?.passing?.interceptions;

        case BetMarketTypeEnum.PLAYER_PROP_RUSHING_YARDS:
          return player?.rushing?.yards;
        case BetMarketTypeEnum.PLAYER_PROP_RUSHING_ATTEMPTS:
          return player?.rushing?.attempts;
        case BetMarketTypeEnum.PLAYER_PROP_LONGEST_RUSH:
          return player?.rushing?.longestYards;
        case BetMarketTypeEnum.PLAYER_PROP_ANY_TIME_RUSHING_TOUCHDOWN:
          return player?.rushing?.touchdowns;

        case BetMarketTypeEnum.PLAYER_PROP_ANY_TIME_TOUCHDOWN:
          return player?.rushing && player?.receiving && player.rushing.touchdowns + player.receiving.touchdowns;

        case BetMarketTypeEnum.PLAYER_PROP_TOTAL_RECEPTIONS:
          return player?.receiving?.receptions;
        case BetMarketTypeEnum.PLAYER_PROP_RECEIVING_YARDS:
          return player?.receiving?.yards;
        case BetMarketTypeEnum.PLAYER_PROP_ANY_TIME_RECEIVING_TOUCHDOWN:
          return player?.receiving?.touchdowns;
        case BetMarketTypeEnum.PLAYER_PROP_LONGEST_RECEPTION:
          return player?.receiving?.longestYards;

        case BetMarketTypeEnum.PLAYER_PROP_TOTAL_SCRIMMAGE_YARDS:
          return player?.receiving && player?.rushing && player.receiving.yards + player.rushing.yards;

        case BetMarketTypeEnum.PLAYER_PROP_KICKING_POINTS:
          return player?.kicking && player.kicking.extraPointMade + player.kicking.fieldGoalMade * 3;
        case BetMarketTypeEnum.PLAYER_PROP_EXTRA_POINT_MADE:
          return player?.kicking?.extraPointMade;
        case BetMarketTypeEnum.PLAYER_PROP_FIELD_GOAL_ATTEMPTED:
          return player?.kicking?.fieldGoalAttempts;

        case BetMarketTypeEnum.PLAYER_PROP_SOLO_TACKLES:
          return player?.defense?.tackles;
        case BetMarketTypeEnum.PLAYER_PROP_SACKS:
          return player?.defense?.sacks;
        case BetMarketTypeEnum.PLAYER_PROP_CAUGHT_INTERCEPTIONS:
          return player?.defense?.interceptions;
        default:
          break;
      }
      break;
    }

    case Sports.BASKETBALL: {
      // eslint-disable-next-line no-case-declarations
      const gameType = game as BasketballLiveDataTypeFragment;
      const player = gameType.players?.find((p) => p.playerId === playerId);
      const totalQuaters = league === League.NCAAB || league === League.WNBA ? 2 : 4;
      switch (betMarketType) {
        // Total Game Points
        case BetMarketTypeEnum.TOTAL_GAME_POINTS:
          return sumArray(gameType.awayTeamTotalPointsByQuarter) + sumArray(gameType.homeTeamTotalPointsByQuarter);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FIRST_QUARTER:
          return (gameType.awayTeamTotalPointsByQuarter?.[0] || 0) + (gameType.homeTeamTotalPointsByQuarter?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SECOND_QUARTER:
          return (gameType.awayTeamTotalPointsByQuarter?.[1] || 0) + (gameType.homeTeamTotalPointsByQuarter?.[1] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_THIRD_QUARTER:
          return (gameType.awayTeamTotalPointsByQuarter?.[2] || 0) + (gameType.homeTeamTotalPointsByQuarter?.[2] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FOURTH_QUARTER:
          return (gameType.awayTeamTotalPointsByQuarter?.[3] || 0) + (gameType.homeTeamTotalPointsByQuarter?.[3] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FIRST_HALF:
          return (
            sumArray(gameType.awayTeamTotalPointsByQuarter?.slice(0, totalQuaters / 2)) +
            sumArray(gameType.homeTeamTotalPointsByQuarter?.slice(0, totalQuaters / 2))
          );
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SECOND_HALF:
          return (
            sumArray(gameType.awayTeamTotalPointsByQuarter?.slice(totalQuaters / 2)) +
            sumArray(gameType.homeTeamTotalPointsByQuarter?.slice(totalQuaters / 2))
          );
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM:
          return isAway ? sumArray(gameType.awayTeamTotalPointsByQuarter) : sumArray(gameType.homeTeamTotalPointsByQuarter);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FIRST_QUARTER:
          return isAway ? gameType.awayTeamTotalPointsByQuarter?.[0] || 0 : gameType.homeTeamTotalPointsByQuarter?.[0] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SECOND_QUARTER:
          return isAway ? gameType.awayTeamTotalPointsByQuarter?.[1] || 0 : gameType.homeTeamTotalPointsByQuarter?.[1] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_THIRD_QUARTER:
          return isAway ? gameType.awayTeamTotalPointsByQuarter?.[2] || 0 : gameType.homeTeamTotalPointsByQuarter?.[2] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FOURTH_QUARTER:
          return isAway ? gameType.awayTeamTotalPointsByQuarter?.[3] || 0 : gameType.homeTeamTotalPointsByQuarter?.[3] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FIRST_HALF:
          return isAway
            ? sumArray(gameType.awayTeamTotalPointsByQuarter?.slice(0, totalQuaters / 2))
            : sumArray(gameType.homeTeamTotalPointsByQuarter?.slice(0, totalQuaters / 2));
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SECOND_HALF:
          return isAway
            ? sumArray(gameType.awayTeamTotalPointsByQuarter?.slice(totalQuaters / 2))
            : sumArray(gameType.homeTeamTotalPointsByQuarter?.slice(totalQuaters / 2));

        // Spreads
        case BetMarketTypeEnum.SPREAD:
          return formatValue(sumArray(gameType.awayTeamTotalPointsByQuarter) - sumArray(gameType.homeTeamTotalPointsByQuarter));
        case BetMarketTypeEnum.SPREAD_FIRST_QUARTER:
          return formatValue(
            (gameType.awayTeamTotalPointsByQuarter?.[0] || 0) - (gameType.homeTeamTotalPointsByQuarter?.[0] || 0),
          );
        case BetMarketTypeEnum.SPREAD_SECOND_QUARTER:
          return formatValue(
            (gameType.awayTeamTotalPointsByQuarter?.[1] || 0) - (gameType.homeTeamTotalPointsByQuarter?.[1] || 0),
          );
        case BetMarketTypeEnum.SPREAD_THIRD_QUARTER:
          return formatValue(
            (gameType.awayTeamTotalPointsByQuarter?.[2] || 0) - (gameType.homeTeamTotalPointsByQuarter?.[2] || 0),
          );
        case BetMarketTypeEnum.SPREAD_FOURTH_QUARTER:
          return formatValue(
            (gameType.awayTeamTotalPointsByQuarter?.[3] || 0) - (gameType.homeTeamTotalPointsByQuarter?.[3] || 0),
          );
        case BetMarketTypeEnum.SPREAD_FIRST_HALF:
          return formatValue(
            sumArray(gameType.awayTeamTotalPointsByQuarter?.slice(0, totalQuaters / 2)) -
              sumArray(gameType.homeTeamTotalPointsByQuarter?.slice(0, totalQuaters / 2)),
          );
        case BetMarketTypeEnum.SPREAD_SECOND_HALF:
          return formatValue(
            sumArray(gameType.awayTeamTotalPointsByQuarter?.slice(totalQuaters / 2)) -
              sumArray(gameType.homeTeamTotalPointsByQuarter?.slice(totalQuaters / 2)),
          );

        // Player Props
        case BetMarketTypeEnum.PLAYER_PROP_POINTS:
          return player?.points;
        case BetMarketTypeEnum.PLAYER_PROP_FIELD_GOAL_ATTEMPTED:
          return player?.fieldGoalAttempted;
        case BetMarketTypeEnum.PLAYER_PROP_FIELD_GOAL_MADE:
          return player?.fieldGoalMade;
        case BetMarketTypeEnum.PLAYER_PROP_THREE_POINTER_ATTEMPTED:
          return player?.threePointersAttempted;
        case BetMarketTypeEnum.PLAYER_PROP_THREE_POINTER:
          return player?.threePointersMade;
        case BetMarketTypeEnum.PLAYER_PROP_FREE_THROW_ATTEMPTED:
          return player?.freeThrowsAttempted;
        case BetMarketTypeEnum.PLAYER_PROP_FREE_THROW_MADE:
          return player?.freeThrowsMade;
        case BetMarketTypeEnum.PLAYER_PROP_REBOUNDS:
          return player?.rebounds;
        case BetMarketTypeEnum.PLAYER_PROP_ASSISTS:
          return player?.assists;
        case BetMarketTypeEnum.PLAYER_PROP_TURNOVERS:
          return player?.turnovers;
        case BetMarketTypeEnum.PLAYER_PROP_STEALS:
          return player?.steals;
        case BetMarketTypeEnum.PLAYER_PROP_BLOCKS:
          return player?.blocks;
        case BetMarketTypeEnum.PLAYER_PROP_STEALS_BLOCKS:
          return player && player.steals + player.blocks;
        case BetMarketTypeEnum.PLAYER_PROP_TURNOVERS_STEALS:
          return player && player.turnovers + player.steals;
        case BetMarketTypeEnum.PLAYER_PROP_TURNOVERS_STEALS_BLOCKS:
          return player && player.turnovers + player.steals + player.blocks;
        case BetMarketTypeEnum.PLAYER_PROP_REBOUNDS_ASSISTS:
          return player && player.rebounds + player.assists;
        case BetMarketTypeEnum.PLAYER_PROP_POINTS_ASSISTS:
          return player && player.points + player.assists;
        case BetMarketTypeEnum.PLAYER_PROP_POINTS_ASSISTS_REBOUNDS:
          return player && player.points + player.assists + player.rebounds;
        case BetMarketTypeEnum.PLAYER_PROP_POINTS_REBOUNDS_BLOCKS:
          return player && player.points + player.rebounds + player.blocks;
        case BetMarketTypeEnum.PLAYER_PROP_POINTS_BLOCKS:
          return player && player.points + player.blocks;
        case BetMarketTypeEnum.PLAYER_PROP_POINTS_REBOUNDS:
          return player && player.points + player.rebounds;
        case BetMarketTypeEnum.PLAYER_PROP_FOULS:
          return player?.personalFouls;
        case BetMarketTypeEnum.PLAYER_PROP_DOUBLE_DOUBLE:
          return player && `${player.points}-${player.rebounds}-${player.assists}`;
        case BetMarketTypeEnum.PLAYER_PROP_TRIPLE_DOUBLE:
          return player && `${player.points}-${player.rebounds}-${player.assists}`;

        default:
          break;
      }
      break;
    }

    case Sports.SOCCER: {
      // eslint-disable-next-line no-case-declarations
      const gameType = game as SoccerLiveDataTypeFragment;
      switch (betMarketType) {
        // Total Game Points
        case BetMarketTypeEnum.TOTAL_GAME_POINTS:
          return sumArray(gameType.awayTeamScoreByHalf) + sumArray(gameType.homeTeamScoreByHalf);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FIRST_HALF:
          return (gameType.awayTeamScoreByHalf?.[0] || 0) + (gameType.homeTeamScoreByHalf?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SECOND_HALF:
          return (gameType.awayTeamScoreByHalf?.[1] || 0) + (gameType.homeTeamScoreByHalf?.[1] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM:
          return isAway ? sumArray(gameType.awayTeamScoreByHalf) : sumArray(gameType.homeTeamScoreByHalf);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FIRST_HALF:
          return isAway ? gameType.awayTeamScoreByHalf?.[0] : gameType.homeTeamScoreByHalf?.[0];
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SECOND_HALF:
          return isAway ? gameType.awayTeamScoreByHalf?.[1] : gameType.homeTeamScoreByHalf?.[1];

        case BetMarketTypeEnum.TOTAL_GAME_CARDS:
          return (
            sumArray(gameType.awayTeamRedCardsByHalf) +
            sumArray(gameType.homeTeamRedCardsByHalf) +
            sumArray(gameType.awayTeamYellowCardsByHalf) +
            sumArray(gameType.homeTeamYellowCardsByHalf)
          );
        case BetMarketTypeEnum.TOTAL_GAME_CARDS_FIRST_HALF:
          return (
            (gameType.awayTeamRedCardsByHalf?.[0] || 0) +
            (gameType.homeTeamRedCardsByHalf?.[0] || 0) +
            (gameType.awayTeamYellowCardsByHalf?.[0] || 0) +
            (gameType.homeTeamYellowCardsByHalf?.[0] || 0)
          );
        case BetMarketTypeEnum.TOTAL_GAME_CARDS_SECOND_HALF:
          return (
            (gameType.awayTeamRedCardsByHalf?.[1] || 0) +
            (gameType.homeTeamRedCardsByHalf?.[1] || 0) +
            (gameType.awayTeamYellowCardsByHalf?.[1] || 0) +
            (gameType.homeTeamYellowCardsByHalf?.[1] || 0)
          );

        case BetMarketTypeEnum.TOTAL_GAME_CARDS_TEAM:
          return isAway
            ? sumArray(gameType.awayTeamRedCardsByHalf) + sumArray(gameType.awayTeamYellowCardsByHalf)
            : sumArray(gameType.homeTeamRedCardsByHalf) + sumArray(gameType.homeTeamYellowCardsByHalf);
        case BetMarketTypeEnum.TOTAL_GAME_CARDS_TEAM_FIRST_HALF:
          return isAway
            ? (gameType.awayTeamRedCardsByHalf?.[0] || 0) + (gameType.awayTeamYellowCardsByHalf?.[0] || 0)
            : (gameType.homeTeamRedCardsByHalf?.[0] || 0) + (gameType.homeTeamYellowCardsByHalf?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_CARDS_TEAM_SECOND_HALF:
          return isAway
            ? (gameType.awayTeamRedCardsByHalf?.[1] || 0) + (gameType.awayTeamYellowCardsByHalf?.[1] || 0)
            : (gameType.homeTeamRedCardsByHalf?.[1] || 0) + (gameType.homeTeamYellowCardsByHalf?.[1] || 0);

        case BetMarketTypeEnum.TOTAL_GAME_YELLOW_CARDS:
          return sumArray(gameType.awayTeamYellowCardsByHalf) + sumArray(gameType.homeTeamYellowCardsByHalf);
        case BetMarketTypeEnum.TOTAL_GAME_YELLOW_CARDS_FIRST_HALF:
          return (gameType.awayTeamYellowCardsByHalf?.[0] || 0) + (gameType.homeTeamYellowCardsByHalf?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_YELLOW_CARDS_SECOND_HALF:
          return (gameType.awayTeamYellowCardsByHalf?.[1] || 0) + (gameType.homeTeamYellowCardsByHalf?.[1] || 0);

        case BetMarketTypeEnum.TOTAL_GAME_YELLOW_CARDS_TEAM:
          return isAway ? sumArray(gameType.awayTeamYellowCardsByHalf) : sumArray(gameType.homeTeamYellowCardsByHalf);
        case BetMarketTypeEnum.TOTAL_GAME_YELLOW_CARDS_TEAM_FIRST_HALF:
          return isAway ? gameType.awayTeamYellowCardsByHalf?.[0] || 0 : gameType.homeTeamYellowCardsByHalf?.[0] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_YELLOW_CARDS_TEAM_SECOND_HALF:
          return isAway ? gameType.awayTeamYellowCardsByHalf?.[1] || 0 : gameType.homeTeamYellowCardsByHalf?.[1] || 0;

        case BetMarketTypeEnum.TOTAL_GAME_CORNERS:
          return sumArray(gameType.awayTeamCornersByHalf) + sumArray(gameType.homeTeamCornersByHalf);
        case BetMarketTypeEnum.TOTAL_GAME_CORNERS_FIRST_HALF:
          return (gameType.awayTeamCornersByHalf?.[0] || 0) + (gameType.homeTeamCornersByHalf?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_CORNERS_SECOND_HALF:
          return (gameType.awayTeamCornersByHalf?.[1] || 0) + (gameType.homeTeamCornersByHalf?.[1] || 0);

        case BetMarketTypeEnum.TOTAL_GAME_CORNERS_TEAM:
          return isAway ? sumArray(gameType.awayTeamCornersByHalf) : sumArray(gameType.homeTeamCornersByHalf);
        case BetMarketTypeEnum.TOTAL_GAME_CORNERS_TEAM_FIRST_HALF:
          return isAway ? gameType.awayTeamCornersByHalf?.[0] || 0 : gameType.homeTeamCornersByHalf?.[0] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_CORNERS_TEAM_SECOND_HALF:
          return isAway ? gameType.awayTeamCornersByHalf?.[1] || 0 : gameType.homeTeamCornersByHalf?.[1] || 0;

        case BetMarketTypeEnum.TOTAL_GAME_FOULS_CONCEDED:
          return sumArray(gameType.awayTeamFoulsByHalf) + sumArray(gameType.homeTeamFoulsByHalf);
        case BetMarketTypeEnum.TOTAL_GAME_FOULS_CONCEDED_TEAM:
          return isAway ? sumArray(gameType.awayTeamFoulsByHalf) : sumArray(gameType.homeTeamFoulsByHalf);

        case BetMarketTypeEnum.TOTAL_GAME_OFFSIDES:
          return sumArray(gameType.awayTeamOffsidesByHalf) + sumArray(gameType.homeTeamOffsidesByHalf);
        case BetMarketTypeEnum.TOTAL_GAME_OFFSIDES_FIRST_HALF:
          return (gameType.awayTeamOffsidesByHalf?.[0] || 0) + (gameType.homeTeamOffsidesByHalf?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_OFFSIDES_SECOND_HALF:
          return (gameType.awayTeamOffsidesByHalf?.[1] || 0) + (gameType.homeTeamOffsidesByHalf?.[1] || 0);

        case BetMarketTypeEnum.TOTAL_GAME_OFFSIDES_TEAM:
          return isAway ? sumArray(gameType.awayTeamOffsidesByHalf) : sumArray(gameType.homeTeamOffsidesByHalf);

        // Spreads
        case BetMarketTypeEnum.SPREAD:
          return formatValue(sumArray(gameType.awayTeamScoreByHalf) - sumArray(gameType.homeTeamScoreByHalf));
        case BetMarketTypeEnum.SPREAD_FIRST_HALF:
          return formatValue((gameType.awayTeamScoreByHalf?.[0] || 0) - (gameType.homeTeamScoreByHalf?.[0] || 0));
        case BetMarketTypeEnum.SPREAD_SECOND_HALF:
          return formatValue((gameType.awayTeamScoreByHalf?.[1] || 0) - (gameType.homeTeamScoreByHalf?.[1] || 0));

        case BetMarketTypeEnum.SPREAD_CORNERS:
          return formatValue(sumArray(gameType.awayTeamCornersByHalf) - sumArray(gameType.homeTeamCornersByHalf));
        case BetMarketTypeEnum.SPREAD_CORNERS_FIRST_HALF:
          return formatValue((gameType.awayTeamCornersByHalf?.[0] || 0) - (gameType.homeTeamCornersByHalf?.[0] || 0));
        case BetMarketTypeEnum.SPREAD_CORNERS_SECOND_HALF:
          return formatValue((gameType.awayTeamCornersByHalf?.[1] || 0) - (gameType.homeTeamCornersByHalf?.[1] || 0));

        default:
          break;
      }
      break;
    }

    case Sports.BASEBALL: {
      // eslint-disable-next-line no-case-declarations
      const gameType = game as BaseballLiveDataTypeFragment;
      const totalInnings = 10;
      switch (betMarketType) {
        // Total Game Points
        case BetMarketTypeEnum.TOTAL_GAME_POINTS:
          return sumArray(gameType.awayTeamScoreByInning) + sumArray(gameType.homeTeamScoreByInning);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FIRST_INNING:
          return (gameType.awayTeamScoreByInning?.[0] || 0) + (gameType.homeTeamScoreByInning?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SECOND_INNING:
          return (gameType.awayTeamScoreByInning?.[1] || 0) + (gameType.homeTeamScoreByInning?.[1] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_THIRD_INNING:
          return (gameType.awayTeamScoreByInning?.[2] || 0) + (gameType.homeTeamScoreByInning?.[2] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FOURTH_INNING:
          return (gameType.awayTeamScoreByInning?.[3] || 0) + (gameType.homeTeamScoreByInning?.[3] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FIFTH_INNING:
          return (gameType.awayTeamScoreByInning?.[4] || 0) + (gameType.homeTeamScoreByInning?.[4] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SIXTH_INNING:
          return (gameType.awayTeamScoreByInning?.[5] || 0) + (gameType.homeTeamScoreByInning?.[5] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SEVENTH_INNING:
          return (gameType.awayTeamScoreByInning?.[6] || 0) + (gameType.homeTeamScoreByInning?.[6] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_EIGHTH_INNING:
          return (gameType.awayTeamScoreByInning?.[7] || 0) + (gameType.homeTeamScoreByInning?.[7] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_NINTH_INNING:
          return (gameType.awayTeamScoreByInning?.[8] || 0) + (gameType.homeTeamScoreByInning?.[8] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TENTH_INNING:
          return (gameType.awayTeamScoreByInning?.[9] || 0) + (gameType.homeTeamScoreByInning?.[9] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FIRST_HALF:
          return (
            sumArray(gameType.awayTeamScoreByInning?.slice(0, totalInnings / 2)) +
            sumArray(gameType.homeTeamScoreByInning?.slice(0, totalInnings / 2))
          );
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SECOND_HALF:
          return (
            sumArray(gameType.awayTeamScoreByInning?.slice(totalInnings / 2)) +
            sumArray(gameType.homeTeamScoreByInning?.slice(totalInnings / 2))
          );
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM:
          return isAway ? sumArray(gameType.awayTeamScoreByInning) : sumArray(gameType.homeTeamScoreByInning);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FIRST_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[0] || 0 : gameType.homeTeamScoreByInning?.[0] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SECOND_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[1] || 0 : gameType.homeTeamScoreByInning?.[1] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_THIRD_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[2] || 0 : gameType.homeTeamScoreByInning?.[2] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FOURTH_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[3] || 0 : gameType.homeTeamScoreByInning?.[3] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FIFTH_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[4] || 0 : gameType.homeTeamScoreByInning?.[4] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SIXTH_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[5] || 0 : gameType.homeTeamScoreByInning?.[5] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SEVENTH_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[6] || 0 : gameType.homeTeamScoreByInning?.[3] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_EIGHTH_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[7] || 0 : gameType.homeTeamScoreByInning?.[7] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_NINTH_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[8] || 0 : gameType.homeTeamScoreByInning?.[8] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_TENTH_INNING:
          return isAway ? gameType.awayTeamScoreByInning?.[9] || 0 : gameType.homeTeamScoreByInning?.[9] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FIRST_HALF:
          return isAway
            ? sumArray(gameType.awayTeamScoreByInning?.slice(0, totalInnings / 2))
            : sumArray(gameType.homeTeamScoreByInning?.slice(0, totalInnings / 2));
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SECOND_HALF:
          return isAway
            ? sumArray(gameType.awayTeamScoreByInning?.slice(totalInnings / 2))
            : sumArray(gameType.homeTeamScoreByInning?.slice(totalInnings / 2));

        // Spreads
        case BetMarketTypeEnum.SPREAD:
          return formatValue(sumArray(gameType.awayTeamScoreByInning) - sumArray(gameType.homeTeamScoreByInning));
        case BetMarketTypeEnum.SPREAD_FIRST_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[0] || 0) - (gameType.homeTeamScoreByInning?.[0] || 0));
        case BetMarketTypeEnum.SPREAD_SECOND_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[1] || 0) - (gameType.homeTeamScoreByInning?.[1] || 0));
        case BetMarketTypeEnum.SPREAD_THIRD_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[2] || 0) - (gameType.homeTeamScoreByInning?.[2] || 0));
        case BetMarketTypeEnum.SPREAD_FOURTH_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[3] || 0) - (gameType.homeTeamScoreByInning?.[3] || 0));
        case BetMarketTypeEnum.SPREAD_FIFTH_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[4] || 0) - (gameType.homeTeamScoreByInning?.[4] || 0));
        case BetMarketTypeEnum.SPREAD_SIXTH_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[5] || 0) - (gameType.homeTeamScoreByInning?.[5] || 0));
        case BetMarketTypeEnum.SPREAD_SEVENTH_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[6] || 0) - (gameType.homeTeamScoreByInning?.[3] || 0));
        case BetMarketTypeEnum.SPREAD_EIGHTH_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[7] || 0) - (gameType.homeTeamScoreByInning?.[7] || 0));
        case BetMarketTypeEnum.SPREAD_NINTH_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[8] || 0) - (gameType.homeTeamScoreByInning?.[8] || 0));
        case BetMarketTypeEnum.SPREAD_TENTH_INNING:
          return formatValue((gameType.awayTeamScoreByInning?.[9] || 0) - (gameType.homeTeamScoreByInning?.[9] || 0));
        case BetMarketTypeEnum.SPREAD_FIRST_HALF:
          return formatValue(
            sumArray(gameType.awayTeamScoreByInning?.slice(0, totalInnings / 2)) -
              sumArray(gameType.homeTeamScoreByInning?.slice(0, totalInnings / 2)),
          );
        case BetMarketTypeEnum.SPREAD_SECOND_HALF:
          return formatValue(
            sumArray(gameType.awayTeamScoreByInning?.slice(totalInnings / 2)) -
              sumArray(gameType.homeTeamScoreByInning?.slice(totalInnings / 2)),
          );

        default:
          break;
      }
      break;
    }

    case Sports.ICE_HOCKEY: {
      // eslint-disable-next-line no-case-declarations
      const gameType = game as IceHockeyLiveDataTypeFragment;
      switch (betMarketType) {
        // Total Game Points
        case BetMarketTypeEnum.TOTAL_GAME_POINTS:
          return sumArray(gameType.awayTeamGoalsByPeriod) + sumArray(gameType.homeTeamGoalsByPeriod);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_FIRST_QUARTER:
          return (gameType.awayTeamGoalsByPeriod?.[0] || 0) + (gameType.homeTeamGoalsByPeriod?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_SECOND_QUARTER:
          return (gameType.awayTeamGoalsByPeriod?.[1] || 0) + (gameType.homeTeamGoalsByPeriod?.[1] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_THIRD_QUARTER:
          return (gameType.awayTeamGoalsByPeriod?.[2] || 0) + (gameType.homeTeamGoalsByPeriod?.[2] || 0);

        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM:
          return isAway ? sumArray(gameType.awayTeamGoalsByPeriod) : sumArray(gameType.homeTeamGoalsByPeriod);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_FIRST_QUARTER:
          return isAway ? gameType.awayTeamGoalsByPeriod?.[0] || 0 : gameType.homeTeamGoalsByPeriod?.[0] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_SECOND_QUARTER:
          return isAway ? gameType.awayTeamGoalsByPeriod?.[1] || 0 : gameType.homeTeamGoalsByPeriod?.[1] || 0;
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM_THIRD_QUARTER:
          return isAway ? gameType.awayTeamGoalsByPeriod?.[2] || 0 : gameType.homeTeamGoalsByPeriod?.[2] || 0;

        // Spreads
        case BetMarketTypeEnum.SPREAD:
          return formatValue(sumArray(gameType.awayTeamGoalsByPeriod) - sumArray(gameType.homeTeamGoalsByPeriod));
        case BetMarketTypeEnum.SPREAD_FIRST_QUARTER:
          return formatValue((gameType.awayTeamGoalsByPeriod?.[0] || 0) - (gameType.homeTeamGoalsByPeriod?.[0] || 0));
        case BetMarketTypeEnum.SPREAD_SECOND_QUARTER:
          return formatValue((gameType.awayTeamGoalsByPeriod?.[1] || 0) - (gameType.homeTeamGoalsByPeriod?.[1] || 0));
        case BetMarketTypeEnum.SPREAD_THIRD_QUARTER:
          return formatValue((gameType.awayTeamGoalsByPeriod?.[2] || 0) - (gameType.homeTeamGoalsByPeriod?.[2] || 0));

        default:
          break;
      }
      break;
    }

    case Sports.TENNIS: {
      // eslint-disable-next-line no-case-declarations
      const gameType = game as TennisLiveDataTypeFragment;
      switch (betMarketType) {
        // Total Game Points
        case BetMarketTypeEnum.TOTAL_GAME_SETS:
          return gameType.player1PointsBySet?.length;
        case BetMarketTypeEnum.TOTAL_GAME_GAMES_FIRST_SET:
          return (gameType.player1GamesBySet?.[0] || 0) + (gameType.player2GamesBySet?.[0] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_GAMES_SECOND_SET:
          return (gameType.player1GamesBySet?.[1] || 0) + (gameType.player2GamesBySet?.[1] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_GAMES_THIRD_SET:
          return (gameType.player1GamesBySet?.[2] || 0) + (gameType.player2GamesBySet?.[2] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_GAMES_FOURTH_SET:
          return (gameType.player1GamesBySet?.[3] || 0) + (gameType.player2GamesBySet?.[3] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_GAMES_FIFTH_SET:
          return (gameType.player1GamesBySet?.[4] || 0) + (gameType.player2GamesBySet?.[4] || 0);
        case BetMarketTypeEnum.TOTAL_GAME_ACES:
          return sumArray(gameType.player1AcesBySet) + sumArray(gameType.player2AcesBySet);
        case BetMarketTypeEnum.TOTAL_GAME_POINTS_TEAM:
          return isAway ? gameType.player2Points : gameType.player1Points;
        // case BetMarketTypeEnum.TOTAL_GAME_GAMES_TEAM:
        //   return isAway ? gameType.player2Points : gameType. player1Points;

        // Spreads
        case BetMarketTypeEnum.SPREAD:
          return formatValue(sumArray(gameType.player1PointsBySet) - sumArray(gameType.player2PointsBySet));
        case BetMarketTypeEnum.SPREAD_FIRST_SET:
          return formatValue((gameType.player1PointsBySet?.[0] || 0) - (gameType.player2PointsBySet?.[0] || 0));
        case BetMarketTypeEnum.SPREAD_SECOND_SET:
          return formatValue((gameType.player1PointsBySet?.[1] || 0) - (gameType.player2PointsBySet?.[1] || 0));
        case BetMarketTypeEnum.SPREAD_THIRD_SET:
          return formatValue((gameType.player1PointsBySet?.[2] || 0) - (gameType.player2PointsBySet?.[2] || 0));
        case BetMarketTypeEnum.SPREAD_FOURTH_SET:
          return formatValue((gameType.player1PointsBySet?.[3] || 0) - (gameType.player2PointsBySet?.[3] || 0));
        case BetMarketTypeEnum.SPREAD_FIFTH_SET:
          return formatValue((gameType.player1PointsBySet?.[4] || 0) - (gameType.player2PointsBySet?.[4] || 0));
        default:
          break;
      }
      break;
    }

    default:
      break;
  }
  return null;
}

function useGetCurrentlyFromLiveData(
  gameId: string,
  league: League,
  betMarketType: BetMarketTypeEnum,
  teamId?: number,
  playerId?: number,
) {
  const gameLiveData = useAppSelector((state) => state.gameLiveDataReducer);
  const gameData = useAppSelector((state) => state.gameDataCacheReducer);
  const curreGameLiveData = gameLiveData[gameId];
  if (!curreGameLiveData) return null;
  const isAway = gameData.gameData[gameId]?.awayTeam === teamId;
  return getCurrentlyFromGameObject(curreGameLiveData, league, betMarketType, isAway, playerId);
}

export default useGetCurrentlyFromLiveData;
