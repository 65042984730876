import React from "react";
import { Box, Stack } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { BaseballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BaseballLiveDataTypeFragment";
import { useTheme } from "@/theme/ThemeContext";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import ElapsedTime from "../ElapsedTime";
import { LiveDataDesign } from "../DesignEnum";

type CompactBaseballDesignProps = {
    gameLiveData: BaseballLiveDataTypeFragment;
    elapsedTime: string;
    isFuture: boolean;
};


// Utility function to render three bases as rhomboids
function RenderBases(bases: boolean[]) {
    const theme = useTheme();
    if (!bases || bases.length === 0) return null;

    return (
        <Box sx={{ position: "relative", left: "50%", transform: "translate(-50%)" }}>
            {/* First rhomboid */}
            <Box
                sx={{
                    position: "absolute",
                    width: "7px",
                    height: "7px",
                    background: bases[2] ? theme.palette.secondary.main : theme.palette.divider,
                    transform: "rotate(-45deg)",
                    top: "-12px",
                    left: "-10px", // Adjust positioning
                }}
            />
            {/* Second rhomboid */}
            <Box
                sx={{
                    position: "absolute",
                    width: "7px",
                    height: "7px",
                    background: bases[1] ? theme.palette.secondary.main : theme.palette.divider,
                    transform: "rotate(-45deg)",
                    top: "-19px",
                    left: "-3px", // Centered horizontally
                }}
            />
            {/* Third rhomboid */}
            <Box
                sx={{
                    position: "absolute",
                    width: "7px",
                    height: "7px",
                    background: bases[0] ? theme.palette.secondary.main : theme.palette.divider,
                    transform: "rotate(-45deg)",
                    top: "-12px", // Positioned below the first two
                    left: "4px", // Align with first rhomboid
                }}
            />
        </Box>
    );
}

function RenderOuts(outs: number[]) {
    const theme = useTheme();
    const latestOuts = outs?.[outs.length - 1] || 0; // Default to 0 if the array is empty

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {Array.from({ length: 3 }, (_, index) => (
                <Box
                    key={index}
                    sx={{
                        width: "5px",
                        height: "5px",
                        borderRadius: "50%",
                        backgroundColor: index < latestOuts ? theme.palette.secondary.main : theme.palette.divider,
                        margin: "0 2px",
                    }}
                />
            ))}
        </Box>
    );
}

function RenderInningIndicator(inning: number, isHomeTeamBatting: boolean) {
    const theme = useTheme();
    return (
        <Stack direction="column" alignItems="center" sx={{ minWidth: "15px" }}>
            {/* Top Triangle */}
            <Box
                sx={{
                    width: 0,
                    height: 0,
                    borderLeft: "3px solid transparent",
                    borderRight: "3px solid transparent",
                    borderBottom: "6px solid",
                    borderBottomColor: isHomeTeamBatting ? theme.palette.divider : theme.palette.secondary.main,
                }}
            />
            <StyledTypography variant="caption" color="text.primary">
                {inning}
            </StyledTypography>
            {/* Bottom Triangle */}
            <Box
                sx={{
                    width: 0,
                    height: 0,
                    borderLeft: "3px solid transparent",
                    borderRight: "3px solid transparent",
                    borderTop: "6px solid",
                    borderTopColor: isHomeTeamBatting ? theme.palette.secondary.main : theme.palette.divider,
                }}
            />
        </Stack>
    );
}

function CompactBaseballDesign({ gameLiveData, elapsedTime, isFuture }: CompactBaseballDesignProps) {
    const {
        awayTeamScoreByInning = [0],
        homeTeamScoreByInning = [0],
        isHomeTeamBatting = false,
        period = "00",
        currentStrikes = 0,
        currentBalls = 0,
        awayTeamCurrentBases = [false, false, false],
        homeTeamCurrentBases = [false, false, false],
        awayTeamOutsByInning = [0],
        homeTeamOutsByInning = [0],
    } = gameLiveData;

    const homeTeamScore = homeTeamScoreByInning?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByInning?.reduce((acc, curr) => acc + (curr ?? 0), 0);

    const currentOuts = (isHomeTeamBatting ? awayTeamOutsByInning : homeTeamOutsByInning) || [];

    return (
        <>
            <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                <SensorsIcon color="error" sx={{
                    height: "16px",
                }} />
            </Box>
            <Box>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    mt={0.5}
                    mr={0.5}
                    sx={{ width: "80px", overflow: "hidden" }}
                >
                    <Stack direction="column" gap={1} alignItems="end">
                        <ScoreIndicatorLiveData score={homeTeamScore?.toString()} isServing={false} design={LiveDataDesign.COMPACT} />
                        <ScoreIndicatorLiveData score={awayTeamScore?.toString()} isServing={false} design={LiveDataDesign.COMPACT} />
                    </Stack>

                    <Stack direction="column" alignItems="center">
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} pt={2.5}>
                            {RenderBases((isHomeTeamBatting ? homeTeamCurrentBases : awayTeamCurrentBases) || [false, false, false])}
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>{RenderOuts(currentOuts)}</Box>

                        <Stack direction="row" alignItems="center">
                            <StyledTypography mt={0.5} variant="caption" color="text.primary">
                                {currentBalls}
                            </StyledTypography>
                            <StyledTypography mt={0.5} variant="caption" color="text.secondary">
                                -
                            </StyledTypography>
                            <StyledTypography mt={0.5} variant="caption" color="text.primary">
                                {currentStrikes}
                            </StyledTypography>
                        </Stack>
                    </Stack>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <StyledTypography variant="caption" color="text.secondary">
                            {RenderInningIndicator(parseInt(period, 10), isHomeTeamBatting)}
                        </StyledTypography>
                    </Box>
                </Stack>

                <Stack sx={{ alignItems: "center" }}>
                    <ElapsedTime elapsedTime={elapsedTime} isFuture={isFuture} isgameLive />
                </Stack>
            </Box>
        </>
    );
};

export default CompactBaseballDesign;
