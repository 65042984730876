// ElapsedTimeDisplay.tsx
import React from "react";
import { useTranslations } from "next-intl";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { formatCustomElapsedTime } from "./Utils";

type ElapsedTimeDisplayProps = {
    elapsedTime: string;
    isFuture: boolean;
    isgameLive: boolean;
};

function ElapsedTime({ elapsedTime, isFuture, isgameLive }: ElapsedTimeDisplayProps) {
    const t = useTranslations("odds");
    return (
        <StyledTypography
            variant="supScript"
            color={isgameLive ? "text.secondary" : "text.primary"}
            lineClamp={2}
            sx={{
                maxWidth: "60px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
            }}
        >
            {isFuture ? `${t("in")} ${formatCustomElapsedTime(elapsedTime)}` : `${formatCustomElapsedTime(elapsedTime)} ${t("ago")} `}
        </StyledTypography>
    );
}

export default ElapsedTime;
